@import "common/_variables";
@import "~bootstrap/scss/bootstrap";
//sick
// @import "~slick-carousel/slick/slick";
// @import "~slick-carousel/slick/slick-theme";
//fancy box
// @import "~@fancyapps/ui";
//@import "~fancybox/dist/scss/jquery.fancybox.scss";
// Select2
@import '../css/select2.min.css';
// Animation
@import '../css/animate.min.css';
//@import '../../node_modules/jquery-ui/themes/base/all.css';
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px
);

$md: 768px;

@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/utilities';
@import "../../node_modules/slick-carousel/slick/slick.scss";

@import "../css/fancybox.css";

@import "common/_global";
@import "common/_fonts";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "components/_modal";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";
@import "layouts/_tinymce";
@import "layouts/_menu";

@import "pages/_home";
@import "pages/_about";
@import "pages/_inspiration";
@import "pages/_contact";
@import "pages/_checkout";
@import "pages/_category";
@import "pages/_delivery";
@import "pages/_account";
@import "pages/_blog"; 

@import "pages/_product-page";
@import "pages/_product-open";
@import "pages/_basket";

@import 'magento/loading';
@import 'magento/customer';

@import "layouts/_responsive";

//v2 Styles

@import "v2/components/_buttons";
@import "v2/components/_forms";
@import "v2/components/_cards";
@import "v2/components/_modal";
@import "v2/components/_slick";
@import "v2/components/_cookie_popup";

@import "v2/layouts/_header";
@import "v2/layouts/_footer";
@import "v2/layouts/_pages";

@import "v2/pages/_product";
@import "v2/pages/_basket";
@import "v2/pages/_thankyou";
@import "v2/pages/_homepage";
@import "v2/pages/_customer";
@import "v2/pages/_wishlist";

// @import "v2/common/_global";
// @import "v2/common/_variables";
// @import "v2/common/_page_builder.scss";

// @import "v2/layouts/_header";
// @import "v2/layouts/_footer";

// @import "v2/pages/_new-shop";
// @import "v2/pages/_categories";
// @import "v2/pages/_single-item";
// @import "v2/pages/_basket-styles";
// @import "v2/pages/_checkout";
// @import "v2/pages/_wishlist";
// @import "v2/pages/_home";


