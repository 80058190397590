
 .blog-category-index,.blog-post-view {

    #maincontent {
      position: relative;
      max-width: 1000px;
      margin: 170px auto 200px auto;
      padding: 15px;
    }
  
    .addthis_sharing_toolbox {
      display: none;
    }
  
    .breadcrumbs--custom {
      margin-bottom: 30px;
    }
  
    .toolbar {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: right;
        
        li {
          display: inline-block;
          margin-right: 10px;
          &.current {
            font-weight: 700;
          }
        }
      }
      .col-lg-12 {
        padding-right: 0px;
      }
    }
    .blog__post-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        margin-bottom: 50px;
      }
    }
  
    .col-right.sidebar {
      display: none;
      .textwidget,.widget-title {
        color: $white;
      }
      .block-title {
        span {
          font-family: 'TrajanPro';
          background-image: none;
          background-color: #1d194c;
          border: 1px solid #c4c1bc;
          color: $white;
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          padding: 6px;
          position: relative;
        }
      }
    }
  
    .col-main {
      // padding-left: 0;
      // display: inline-block;
      // width: 70%;
      margin-top: 130px;
    }
    
    .blog__sidebar-recent,.blog__post-footer {
      display: none;
    }
  
  }
  