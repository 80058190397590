
.quantity--field{
    border: 0;
    width: auto;
    max-width: 40px;
    text-align: center;
    background: none;
    
}


.cart-forms{
    .shipping{
        display: none;
    }
}

.cart-summary{
    #co-shipping-method-form{
        display: none;
    }
}


.amcheckout-header{
    display: none;
}

.col-method, .col-price, .payment-method{
    @extend .din-r; 
    @extend .fontXX; 
    @extend .text-extraLightBlack;
    @extend .res17;
}

.col-method{
    input[type="radio"], input[type="checkbox"]{
        margin-top: 3px;
    }
}

.payment-method{
    padding: 10px;
}

#checkout{
    color: $black;
   
    .note{
        line-height: 120%;
    }

    .input-text{
        @extend .bg-white;
        @extend .form-simple;
        @extend .border-0;
        @extend .din-r;
        @extend .w-100;
        @extend .fontXX;
        @extend .text-extraLightBlack;
        @extend .py-3;
        @extend .ps-3;
        @extend .mb-2;
        @extend .res14;
    }
}

.amcheckout-summary-container{
    .amcheckout-wrapper{
        @extend .mt-3;
        @extend .bg-white; 
        @extend .px-lg-3; 
        @extend .pt-1; 
        @extend .card-custom;
    }
}

.basket-single{
    .action-delete{
        display: inline-block;
        line-height: 10px;
        vertical-align: top;
        margin-top: 4px;
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}


.amcheckout-checkboxes{
    input[name="additional[subscribe]"]{
        margin-top: 12px;
        font-size: 12px;
        vertical-align: top;
    }
    input[name="additional[subscribe]"] + label{
        margin-top: 10px;
        font-size: 12px;
    }
}

#checkout{
    padding-top: 55px !important;
    padding-bottom: 165px !important;
}

.checkout-header{
    padding: 20px 0px;
}

.amcheckout-wrapper{
    margin-top: 20px;
}

.basket--section{
    .action-towishlist{
        display: none !important;
    }
}