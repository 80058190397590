
.block-content {
  .box-actions {
    a {
      border-bottom: 1px solid;
      margin-right: 10px;
    }
  }
}

.address--block {
  a {
    border-bottom: 1px solid;
    margin-left: 10px;
  }
}

.section--title{
  font-size: 48px;
  text-transform: uppercase;
  position: relative;
  font-family: $sans-serif;
  &.section--title__borderBottom{
    &::after{
      content: '';
      position: absolute;
      height: 4px;
      background: $red--dark;
      left: 50%;
      bottom: 0;
      width: 30px;
      margin-left: -15px;
    }
  }
}

.section--subtitle{
  font-size: 36px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  &::after{
    content: '';
    position: absolute;
    height: 4px;
    background: $red--dark;
    left: 50%;
    bottom: 0;
    width: 30px;
    margin-left: -15px;
  }
}

.stripe, .strip--delivery{
  padding: 20px 0px;
  color: $white;
  font-size: 32px;
  text-transform: uppercase;
  font-family: $sans-serif;
  text-align: center;
}

.product--block{
  position: relative;
  display: block;
  color: $grey--darkest;
  margin-bottom: 40px;
  height: 340px;
  img{
    width: 100%;
    height: auto;
  }

  .product--block__image{
    margin-bottom: 20px;
    position: relative;
  }

  .product--block__price{
    padding-top: 20px;
  }

  &:hover{
    .product--block__overlay{
      opacity: 1;
      visibility: visible;
    }
  }

  .product--block__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: $zindex--product--block__overlay;
    opacity: 0;
    visibility: hidden;
    @include generate-transition(all, 400ms, ease-in-out);
    a{
      display: inline-block;
      margin-bottom: 30px;
      &:last-child(){
        margin-bottom: 0;
      }
    }
  }
}


.customer-account-login{
  .hr--special{
    margin-bottom: 30px;
  }
}


.select2-container--default .select2-selection--single{
  border: 0;
  text-transform: uppercase;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
      border-color: #1D174C transparent transparent transparent;
}


.default--button{
  &.button--orange{
    background: $orange--normal;
    color: $white !important;
    padding: 15px 35px;
    a{
      color: $white;
    }
    &:hover{
      background: $grey--darkest;
    }
  }
}

.swash--button, .action.primary.checkout{
  border: 0;
}


.cms-home{
  .header--spacing{
    padding-top: 0 !important;
  }
}


.cms-delivery{
  .columns{
    max-width: 100%;
    margin: 0px auto;
  }
}

.messages{
  margin-bottom: 0 !important;
}

body .contact--title::after{
  left: 0 !important;
  margin-left: 0 !important;
}

.main-container{
  .main{
    padding: 0px;
  }
}


.cms-page-view{
  .breadcrumbs{
    margin-left: 0;
    margin-top: 0;
  }
  @include media-breakpoint-down(lg) {
    .main .col-lg-12{
      padding: 0 15px;
    }
  }
}


.postTitle {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #DDDDDD;
    margin-bottom: 15px;
    a{
      font-size: 20px;
      font-weight: normal;
      margin: 0px;
      padding: 0px;
    }
}


.amcheckout-main-container{
  //padding-top: 20px;

  .checkout--basket__wrap{
    .checkout--basket__wrap--title, #discount-form, .product--item__wrap .checkout--product__image{
      display: none;
    }
  }

  #discount-form .control .cancelInput{
    top: 0;
    right: 15px;
    &:before{
      display: none;
    }
  }

  #discount-form #discount-code{
    vertical-align: top;
    padding-left: 15px !important;
    border: 1px solid $golden !important;
    width: calc(100% - 15px) !important;
    border-radius: 4px !important;
  }

  #discount-form button{
    position: absolute;
    right: 15px;
    margin-left: 0;
    margin-top: 0px;
    background: #3C3C3C;
    &:before{
      margin-bottom: 0;
    }
  }


  .field{
    margin-bottom: 10px;
  }

  .field.street{
    margin-bottom: 0;
  }

  .field-tooltip-content{
    font-size: 11px;
    padding-top: 5px;
  }

  .product--name strong{
    font-size: 13px;
    font-weight: 400;
  }

  .table-totals{
    th, td{
      text-align: right;
    }

    td{
      padding-right: 0;
    }


  }

  .col-lg-12.pr-0{
    hr{
      display: none;
    }
  }

  .checkout--basket__wrap{
    //padding: 20px 20px 0px 20px;
    .summary--title{
      font-size: 12px;
    }
  }

  // .action.primary.checkout.amasty{
  //   -moz-border-radius: 5px;
  //     -webkit-border-radius: 5px;
  //     border-radius: 5px;
  //     background: #f18200;
  //     border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  //     width: 185px;
  // }

  select{
    padding-left: 10px;
    font-size: 14px;
  }

  .col-carrier{
    margin-bottom: 15px;
  }

}

.amcheckout-step-container input[type='checkbox'] + label, .amcheckout-step-container input[type='radio'] + label{
  padding-left: 0;
}

.notice-cookie .notice-text p{
  font-size: 12px;
}

.amcheckout-step-container .amcheckout-summary-container .amcheckout-content > .amcheckout-wrapper{
  padding: 10px 10px 20px 10px;
}

.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method._active .payment-method-content{
  padding-left: 20px;
}

.amcheckout-title{
  @extend .bg-extraLightBlack; 
  @extend .ps-3; 
  @extend .py-3;
  @extend .text-white; 
  @extend .din-r; 
  @extend .fontSL; 
  @extend .spaceSL;
}

.payment-method-content{
  .action.primary.checkout{
    display: none;
  }
}

body .am-checkout{
  background: none !important;
  padding: 0 !important;
}

.amcheckout-step-container .amcheckout-additional-options{
  background: none;
}

.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col,
.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method-title .label{
  padding: 0;
}

.table-checkout-shipping-method tbody .col-method, .table-checkout-shipping-method tbody .col-price, .table-checkout-shipping-method tbody .col-method-title{
  margin-left: 0 !important;
}

div.field-error, #customer-email-error{
  padding-top: 10px;
  font-size: 12px;
}


.outostock--popup__trigger{
  color: $white !important;
  text-align: center;
}

.modal-popup._inner-scroll{
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  .modal-inner-wrap{
    width: 400px;
  }
}


#gallery-wrapper{
  .owl-prev, .owl-next{
    position: absolute;
    top: 50%;
    background: white !important;
  }

  .owl-prev{
    left: 15px;
  }

  .owl-next{
    right: 15px;
  }
}


.owl-item{
  li{
    list-style: none;
  }
}

.g-recaptcha > div{
  display: inline-block;
  text-align: center;
}

.departments--block__slider{
  .departments--slider{
      .departments--slide{
          padding-top: 20px;
      }
      .img--wrap{
          padding: 20px;
          border-radius: 50%;
          box-shadow: 0px 0px 26px #00000017;
          background-color: $white;
          height: 210px;
          width: 210px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin: auto;
          img{
              object-fit: cover;
              width: 160px;
              max-height: 160px;
              transition: 0.3s;
              &:hover{
                  transform: scale(1.2);
                  transition: 0.3s;
              }
          }
      }
  }
  .slick-slide{
      margin-right: 0px;
      margin-left: 0px;
  }
}

.advice--block{
  height: 230px;
  border-radius: 10px;
  padding-top: 20px;
  .background--images{
      img{
          object-fit: cover;
          height: 230px;
          width: 100%;
          border-radius: 10px;
      }
  }
  .block--content{
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 230px;
  }
  .center--block{
      width: 350px;
      z-index: 1;
  }
  img{
      height: 230px;
  }
  .rips--left{
      position: absolute;
      margin-left: -40px;
      z-index: 0;
  }
  .rips--right{
      position: absolute;
      margin-right: -40px;
      right: 0;
      z-index: 0;
  }
}

.img--wrap{
  img{
      object-fit: cover;
  }
}

.navigation--blocks{
  height: 515px;
  .default--button__red{
      &:hover{
          transition: 0.3s;
          background-color: rgba(213, 21, 40, 0.5);
          
      }
  }
  &.small{
      height: unset;
  }
  .large--block{
      border-radius: 15px;
      height: 515px;
      width: 100%;
      position: relative;
      overflow: hidden;
      display: block;
      &:hover{
          .bg--image{
              transition: 0.3s;
              scale: 1.1;
          }
      }
      .bg--image{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 15px;
          transition: 0.3s;
          @media (max-width: $md) {
              top: unset;
              bottom: 0;
          }
      }
      .blue--rip{
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
      }
      .font--brush{
          transform: rotate(-5deg);
      }
  }
  .small--block{
      height: 250px;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      display: block;

      &:hover{
          .bg--image{
              transition: 0.3s;
              scale: 1.1;
          }
      }
      .gold--rip{
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
      }
      &:hover{
          .img--wrap{
              overflow: hidden;
              transition: 0.3s;
              img{
                  transition: 0.3s;
                  scale: 1.2;
              }
          }
      }
      .bg--image{
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 15px;
          transition: 0.3s;
          @media (max-width: $md) {
              border-radius: 0px;
          }
      }
  }
  .headingM{
      font-family: 'Poppins';
  }
  .bodyM{
      font-family: 'Poppins';
  }
  @media (max-width: $md) {
      .default--button{
          &__red{
              padding: 25px 60px;
          }
          &__blue{
              padding: 25px 60px;
          }
      }
  }
}

// Responsive 

@media (max-width: 768px) {
  body .navigation--blocks{
      height: unset;
      .headingM{
          font-size: 38px;
      }
      &.small{
          height: unset;
      }
      .large--block{
          border-radius: 15px;
          height: 525px;
          width: 100%;
          position: relative;
          padding-top: 20px;
          border-radius: 0;
          
          .align-items-center{
              align-items: start!important;
          }
          .blue--rip{
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 60%;
          }
          .font--brush{
              transform: rotate(-5deg);
              font-size: 90px;
              line-height: 70px;
          }
      }
      .small--block{
          height: 370px;
          border-radius: 15px;
          position: relative;
          border-radius: 0;
          @media (max-width: $md) {
              .mobile--image{
                  height: 370px;
                  object-fit: cover;
                  width: 100%;
              }
              .align-items-center{
              }
              &.second{
                  height: unset;
                  background: none!important;
                  .text{
                      text-align: left!important;
                  }
                  .gold--rip{
                      height: 200%;
                      left: unset;
                      width: 100%;
                  }
              }
          }
          .gold--rip{
              position: absolute;
              left: 0;
              top: 0;
              width: 50%;
              height: 100%;
              @media (max-width: $md) {
                  object-fit: cover;
                  transform: rotate(0deg);
                  width: 100%;
                  height: auto;
                  left: 0;
                  top: 200px;
              }
          }
      }
  }

  .advice--block{
      height: unset;
      border-radius: 0px;
      .background--images{
          img{
              object-fit: cover;
              height: 180px;
              width: 100%;
              border-radius: 0;
          }
      }
      .col-md-6{
          padding: 0px;
      }
      .block--content{
      }
      .center--block{
          width: 100%;
          z-index: 1;
      }
      img{
          height: 230px;
      }
  }
}

.overlap-rip{
  margin-top: -30px;
  object-fit: cover;
  height: 60px;
  width: 100%;
  &.flip{
      margin-top: 0;
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
  }
}
.overlap-rip-bottom{
  width: 100%;
  object-fit: cover;
}


.large--block, .small--block{
  .default--button__red, .default--button__blue{
    display: inline-block;
  }
}

.default--button__blue{
  @extend .border-0; 
  @extend .bg-secondary; 
  @extend .borderRadiusX; 
  @extend .btn-L; 
  @extend .w-100; 
  @extend .mt-2; 
  @extend .articulat-n; 
  @extend .text-extraDarkGray; 
  @extend .fontXL;
}

.cms-page-view main, .blog-post-view main{ 
  p{
    margin: 1rem 0px;
  }
  
}