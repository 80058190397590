

.header--top {
  padding: 0px 0px 15px 30px;
  font-weight: $medium;
  background: $white url('/media/images/blue-rip-down-alt.png') center center / cover no-repeat;

  

  #advanced-search-link  {
    position: relative;
  }

  @include generate-transition (all, 500ms, ease);

  .full--logo {
    width: 200px;
    @include generate-transition (all, 500ms, ease);

    &:hover {
      opacity: 0.5;
    }
  }

  ul {
    list-style: none;

    li {
      display: inline-block;

      a {

        @include generate-transition (all, 500ms, ease);

        &:hover {
          opacity: 0.5;

          i {
            @include animation(shakeThatBooty 2s ease-in-out infinite);
            display: inline-block;
          }
        }

        i {
          margin-right: 8px;
        }
      }
    }
  }

  .text-right {
    ul {
      li {
        a {
          &.search--wrap {
            position: relative;
          }
          position: relative;
          margin-right: 0px;
          margin-left: 45px;
          @include generate-transition (all, 500ms, ease);

          &:hover {
            opacity: 0.5;

            i {
              @include animation(shakeThatBooty 2s ease-in-out infinite);
              display: inline-block;
            }
          }
        }
      }
    }
    .counter {
      background-color: #000;
      position: absolute;
      min-width: 15px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 10px;
      top: -5px;
      left: -7px;
      line-height: 15px;
    }
  }
}

.header--categories {
  position: relative;
  z-index: 101;
  background: $white;
  font-weight: $medium;

  height: 56px;

  &.add--shadow {
    -webkit-box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
  }

  ul {
    padding-top: 15px;
    list-style: none;
    display: inline-block;

    li {
      display: inline-block;
      float: left;
      text-transform: capitalize;
      padding-bottom: 15px;
      vertical-align: top;
      border-bottom: 2px solid rgba($black, 0);

      &:hover {
        border-bottom: 2px solid #242424;
      }

      // &.active, &.has-active {
      //   border-bottom: 2px solid #242424;
      // }
      a {
        margin: 0 15px;

        position: relative;

        @include generate-transition (all, 500ms, ease);

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.promotion--banner {
  font-weight: $medium;
  color: $white;
  background: $green;
  font-size: 14px;
  padding: 10px 0;
  z-index: 1;
  position: relative;
  overflow: hidden;

  @include generate-transition (all, 500ms, ease);
  i {
    margin: 0 10px;
    font-size: 18px;
  }
  &.banner--closed {
    max-height: 0px;
    overflow: hidden;
    padding: 0px;
  }
  .promotion--banner__close--wrap {
    position: absolute;
    top: 50%;
    right: 0px;
    margin-right: 40px;
    transform: translateY(-50%);
    font-size: 25px;
    display: inline-block;
    margin-top: -1px;
    a {
      @include generate-transition(all, 500ms, ease);
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.basket--expand {
  position: absolute;
  right: 0;
  top: 40px;
  height: auto;
  width: 100%;
  max-width: 40vw;
  background: $white;
  z-index: 1;
  font-size: 14px;
  max-height: 0px;
  overflow: hidden;
  visibility: hidden;
  z-index: 250;
  @include generate-transition (all, 1500ms, ease);
  -webkit-box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);

  .ui-widget {
    position: relative;
    padding: 0;
    margin: 0;
    border: none !important;

    .block-minicart {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      padding: 0;
    }
  }

  .item-qty {
    padding: 0;
    margin: 0;
  }

  &.active {
    max-height: 2000px;
    visibility: visible;
  }

  .product--wrap {
    margin-bottom: 10px;

    img {
      max-width: 80px;
      margin-right: 10px;
    }

    input {
      display: inline-block;
      width: 25px;
      height: 25px;
      font-size: 12px;
      text-align: center;
      border: 1px solid rgba($black, 0.2);
      padding-right: 2px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .view--cart__button, .checkout--button {
    a {
      display: block;
      padding: 20px 20px;
      color: $white;
      background: $navy;
      @include generate-transition (all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }

      span {
        border-bottom: 1px solid $white;
      }
    }
  }

  .checkout--button {
    margin-left: 2px;
  }

  .default--link {
    border-bottom: 1px solid $black;
  }

  .basket--expand__close--trigger__wrap {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    font-size: 20px;
    margin-right: 20px;
    margin-top: 10px;
    vertical-align: middle;
    display: inline-block;

    .basket--expand__close--trigger {
      cursor: pointer;
      @include generate-transition(all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }

      span {
        font-size: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-top: -6.5px;
      }
    }
  }

  .product--basket__append {
    height: auto !important;
  }

  .basket--title {
    opacity: 0.5;
  }

  .product--name {
    text-transform: capitalize;
  }
}

.header--dropdown {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
  background: $white;
  max-width: 70vw;
  padding: 40px;
  margin-top: -100px;
  padding-top: 120px;
  @include generate-transition (all, 500ms, ease);
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: -2px 4px 5px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: -2px 4px 5px 0px rgba(0,0,0,0.05);
  box-shadow: -2px 4px 5px 0px rgba(0,0,0,0.05);
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.promotion--banner__closed {
    margin-top: -40px;
    padding-top: 60px;
  }
  ul {
    list-style: none;
    li {
      font-weight: $medium;

      text-transform: capitalize;
      width: 20%;
      display: inline-block;
      min-height: 30px;
      vertical-align: top;
      padding-right: 10px;
      padding-bottom: 10px;

      a {
        position: relative;
        @include generate-transition(all, 500ms, ease);
        &:hover {
          opacity: 0.5;
        }
        &:before, &:after {
          content: '';
          position: absolute;
          width: 0%;
          height: 1px;
          bottom: 0px;
          background: $black;
          opacity: 0.5;
        }

        &:before {
          left: 0px;
        }
        &:after {
          right: 0px;
          background: $black;
          transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &:hover:before {
          background: $black;
          width: 100%;
          transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &:hover:after {
          background: transparent;
          width: 100%;
          transition: 0s;
        }
      }
    }
  }
  .heading {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: $medium;

    line-height: 1.714;
  }
  .icon-whalesale {
    margin-top: 20px;
    display: inline-block;
    font-size: 60px;
  }
}



#searchsuite-autocomplete{
  text-align: left;
  a{
    margin-left: 0;
    font-weight: 400;
    font-size: 13px;
  }

  .reviews-actions, .qs-option-description{
    display:none !important;
  }

  .price{
    font-size: 13px;
  }

  #product{
    padding: 20px 20px 0px 20px;
  }

  .icon-search{
    color: $grey;
    font-size: 16px;
  }

  .row{
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $grey--light;
  }

  .bottom-title{
    padding-bottom: 20px;
  }
}

.search--trigger{
  &.active{
    z-index: 10;
    i{
      margin-right: 0;
    }
    span{
      display:none;
    }
  }
}



#minicart-content-wrapper{
  #mini-cart{
    //max-height: 300px;
    //overflow-x: hidden;
    //overflow-y: scroll;
  }
}

.product--sub__menus--menu__close,
.products--right__menu--wrap__close{
  display: none;
}


// BB Header


.popup--delivery{
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 200;
  font-weight: 700;
  text-transform: uppercase;
  font-family: $sans-serif;

  .icon--close{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    width: 36px;
    height: 36px;
    background: orange;
    border-radius: 50%;
    line-height: 39px;
    text-indent: 1px;
    margin: -15px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
    &:hover{
      background: $black;
      color: $white;
    }
  }

  hr{
    width: 90%;
    margin: 10px auto;
  }

  &__trigger{
    background: $black;
    color: $white;
    padding: 10px 30px 6px;
    font-size: 24px;
    transform: rotate(-90deg);
    margin-right: -90px;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 230px;
    color: $white;
    a{
      color: $white;
      transition: all 400ms ease-in-out;
      text-decoration: none;

      &:hover{
        color: $orange--normal;
      }
    }

  }

  &__interim{
    width: 50px;
    height: 230px;
    margin-top: -90px;
    margin-left: 86px;
    z-index: 9;
    position: absolute;
    transition: all 400ms ease-in-out;
    top: 0;
    right: 0;
    opacity: 0;

    &--background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 400ms ease-in-out;
      background: $black;
    }

    &.active{
      opacity: 1;
      transform: scale3d(12.1, 1.39, 1);
    }
  }

  &__content{
    background: $black;
    padding: 40px 30px;
    color: $white;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    margin-top: -135px;
    position: absolute;
    top: 0;
    right: 0;
    width: 327px;
    z-index: 11;
    opacity: 0;
    visibility: hidden;

    .default--button{
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      font-family: $sans;
      transition: all 400ms ease-in-out;
    }
  }
}


.trek--strap{
  text-transform: uppercase;
  padding: 10px 0px 8px;
  text-align:center;
  span{
    font-family: $sans-serif;
    font-size: 32px;
    vertical-align: middle;
    color: $white;
  }
  .default--button{
    margin-left:20px;
  }
}


header.page-header{
  background: $white;
  padding: 0px 0px 0px;
  overflow: visible;
  z-index: 100;

  &.menu-opened{
    overflow: visible;
  }

  .header--action__menu{
    margin-top: 10px;
    a{
      color: $black;
      margin-left: 10px;
      font-size: 18px;
      &:hover{
        color: $orange--normal;
      }
    }
  }
}



.search--box{
  position: absolute;
  top: 0;
  right: 0;
  background: $grey--darkest;
  width: 400px;
  padding: 10px 25px;
  margin-top: 93px;
  z-index: $zindex--search--box;
  opacity: 0;
  visibility: hidden;
  @include generate-transition(all, 400ms, ease-in-out);

  &.active{
    opacity: 1;
    visibility: visible;
  }


  input[type="search"]{
    border: 0;
    border-radius: 10px;
    display: block;
    padding-left: 20px;
    margin-bottom:0;
  }

  button{
    background: none;
    border: 0;
    position: absolute;
    top: 50%;
    right: 50px;
    margin-top: -14px;
    font-size: 18px;
    border: 0;
  }
}


.product--basket__buttons{
  padding-bottom: 40px;
}

.search-autocomplete:before{
  display: none !important;
}



.smile-elasticsuite-autocomplete-result{
  background: white;
  padding: 0px;

  .price-box{
    padding: 0;
    margin: 0;
  }

  .price-label{
    display: none !important;
  }

  dd{
    padding: 6px;
    margin-bottom: 0;
  }
  dl{
    list-style: none;
    margin-bottom: 0;
  }
}

.autocomplete-list-title{
  padding: 6px;
  background-color: #f4f4f4;
  color: #636363;
  text-transform: uppercase;
}

.smile-elasticsuite-autocomplete-result{
  .amount, .custom-label{
    display: none !important;
  }

  dd{
    cursor: pointer;
  }
}


header.page-header {
  background-color: #1D184C;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  z-index: 100;
  @include generate-transition(all, 500ms, ease);
  @include media-breakpoint-down(md) {
    background-color: $white;
    border-radius: 0px;
    padding: 0;
  }
  @include media-breakpoint-between(md, lg) {
    border-radius: 0px;
  }
  margin-top: 0px;
  &.nav-down {
    margin-top: -50vh !important;
  }
  .mobSearch {
      position: relative;
      padding: 8px !important;
      margin-left: 0px !important;
      display: flex;
      align-items: center;
      a {
        font-size: 0px !important;
        background-color: rgba(33, 37, 41,0.14);
        border-radius: 50%;
        height: 45px;
        width: 45px;
        display: block;
      }
      &:after {
        content: '';
        background-image: url('/wp-content/themes/bic/assets/images/search.svg');
        pointer-events: none;
        position: absolute;
        height: 18px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;


      }

  }
  .logoBlock {
    height: 80px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    @include media-breakpoint-down(sm) {
      border-radius: 0px;
      position: relative;
    }
      .logo {
        padding: 10px;
        height: 100%;

      }
  }



  ul.header--menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    a{
      @include media-breakpoint-up(lg) {
        &:hover{
          opacity: 0.5;
        }
      }
    }


    @include media-breakpoint-down(lg) {
      display: none;
      position: absolute;
      width: 100%;
      z-index: 99;
      top: 0px;
      left: 0;
      background: $white;
      padding: 10px 0px;
      //display: none;o
      margin-left: -100%;
      transition: all 600ms ease;

      &.active{
        margin-left: 0;
        height: calc(100vh - 180px);
        padding-bottom: 140px;
        overflow-y: scroll;
        display: block;
        //padding-bottom: 110px;
      }

      li a{
        font-weight: 400 !important;
      }
    }


    .sub-menu{
      @include media-breakpoint-down(lg) {
       // padding-left: 5px;

        ul.children{
          margin-bottom: 20px;
          padding-right: 0;
          padding-left: 0;
          li{
            padding: 0;
            &:last-child a{
              border-bottom: 0;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .level2.cms_block{
        display: none !important;
      }
    } 

    &>li {
      display: inline-block;
      text-align: center;
      padding: 14px 25px 11px;
      vertical-align: middle;
      @extend .text-decoration-none ;
      @extend .text-white ;
      @extend .fontXM ;
      @extend .text-uppercase ;
      @extend .din-d;

      
      @include media-breakpoint-down(lg) {
        display: block;
        text-align: left;
        position: initial;
        border-bottom: 1px solid $grey--light;
        margin: 0;
        padding: 0;
        background: none !important;

        &.bg-red a{
          color: #CC2325;
        }

        a{
          padding: 15px 18px;
          display: block;
          background: none !important;
          
        }

        &.level2{
          &.parent{
            &::before{
              right: 0;
            }
          }


        }

        &.level0{
          &>a{
            font-size: 20px !important;
            width: 100%;
            &::after{
              content: '';
              background: url(/static/frontend/TheCurious/BCMW/en_GB/images/arrow-menu-black.svg) center / contain no-repeat;
              width: 15px;
              height: 15px;
              position: absolute;
              top: 50%;
              margin-top: -5px;
              margin-right: 15px;
              display: block !important;
              opacity: 1;
              transform: rotate(-90deg);
              right: 0;
            }
            &.active{
              &::after{
                transform: rotate(0deg);
              }
            }
          }
        }

      }

      &.discover {
        cursor: pointer;

        a {
          position: relative;
          &:after {
            content: '';
            background-image: url('/wp-content/themes/bic/assets/images/arrow-down.png');
            position: absolute;
            right: -15px;
            height: 10px;
            width: 10px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
            @include generate-transition(all, 500ms, ease);
          }
        }
        &.active {
          a {
            position: relative;
            &:after {
              content: '';
              background-image: url('/wp-content/themes/bic/assets/images/arrow-down.png');
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
      &.search {
        position: relative;
        padding: 8px !important;
        margin-left: 12px !important;
        display: flex;
        align-items: center;
        a {
          font-size: 0px !important;
          background-color: rgba(255,255,255,0.14);
          border-radius: 50%;
          height: 45px;
          width: 45px;
          display: block;
        }
        &:after {
          content: '';
          background-image: url('/wp-content/themes/bic/assets/images/search.png');
          pointer-events: none;
          position: absolute;
          height: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 18px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;


        }
      }
      i {
        opacity: 0.14;
        margin-right: 5px;
      }
      &.onlineShop {
        background-color: $red;
      }
      a {
        color: $white;
        font-size: 14px;
        @include media-breakpoint-down(lg) {
          color: $black;
          text-transform: none;
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            color: $white;
          }
        }
        
      }
    }
  }
}



.logoBlock{
  width: 200px!important;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.2);
}

.list--noStyles{
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.menu-burger{
  z-index: 100;
  @include media-breakpoint-down(lg) {
    //margin-top: 16px;
    //vertical-align: top;
  }
}

.trust-pilot-mobile{
  width: 60% !important;
  max-width: 200px !important;

  @media (min-width: 720px) and (max-width: 1024.98px) {
    width: 200px !important;
  }
}

.minicart--wrap{
  // @include media-breakpoint-down(lg) {
  //   position: absolute;
  //   top: 78px;
  //   right: 22px;
  // }
}

.header-scrolled{
  .minicart--wrap{
    // @include media-breakpoint-down(lg) {
    //   position: absolute;
    //   top: 51px;
    //   right: -70px;
    // }
  }
}

.header-social a{
  &::after{
    display: none;
  }
}