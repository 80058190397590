

input[type="radio"], input[type="checkbox"] {
  width: initial;
}


textarea {
  height: 160px;
}

select {
  width: 100%;
  height: 43px;
  display: block;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  background-image: url('../images/arrow-select.svg');
  background-size: 12px;
  background-position: top 16px right 10px;
  background-repeat: no-repeat;
}

.shipping{
  input[type="text"]{
    width: 100%;
    height: 43px;
    display: block;
    margin-bottom: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0px;
  }
}


button {
  &.default--link {
    border: none;
    background: transparent;

    > span {
      border-bottom: 1px solid;
    }
  }
}

input[type="submit"] {
  display: inline-block;
  border: none;
  border-bottom: 1px solid $black;
  background: none;
  cursor: pointer;
  @include generate-transition (all, 500ms, ease);

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

div {
  &.mage-error {
    color: #f15351;
  }
  &.field-error {
    color: #f15351;
  }
}
