
.cms-index-index{
    @include media-breakpoint-down(lg) {
        padding-top: 0px;
    }
}

.homepage-v2{


    .top-section{
        position: relative;
        .section-overlay{
            position: absolute;
            bottom: 60px;
            left: 40px;
            width: calc(100% - 60px);
        }

        img{
            width: 100%;
        }

        .mgz-col-md-4{
            .section-overlay{
                left: 30px;
                bottom: 40px;
                width: calc(100% - 70px);
            }
        }

        .mgz-icon-size-md{
            width: 0px;
            height: 0px;
        }

        .mgz-element-icon{
            *{
                text-align: right !important;
                
            }

            .mgz-icon-element{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: $grey--darkest;
                text-align: center;
                line-height: 32px;
                font-size: 18px;
                text-align: center !important;
                text-indent: 2px;
            }
        }
    }
    
    .icons-row{
        .mgz-element-raw_html .mgz-element-inner{
            border-radius: 8px;
            padding: 20px;

            img{
                display: inline-block;
                margin-left: 20px;
            }

            .text-light{
                display: inline-block;
                width: calc(100% - 100px);
            }
        }
    }

    .latest-product{
        position: relative;

        img{
            width: 100%;
            height: auto;
            @include media-breakpoint-down(lg) {
                height: 50vh;
                object-fit: cover;
            }
        }

        .mgz-element, .mgz-element-inner{
            position: static;
        }

        .mgz-single-image-wrapper{
            display: block;
        }

        .latest-product-box{
            position: absolute;
            top: 30%;
            left: 10%;
            width: 500px;
            max-width: 100%;
            background: white;
            border-radius: 10px;
            padding: 40px;
            @include media-breakpoint-down(lg) {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                padding: 30px;
                border-radius: 0;
            }
        }

        .latest-product-roundel{
            position: absolute;
            top: 10%;
            right: 10%;
            background-color: $golden;
            width: 191px;
            min-width: 191px;
            height: 191px;
            line-height: 20px;
            padding: 0 35px;
            border-radius: 50%;
            @include media-breakpoint-down(lg) {
                width: 161px;
                min-width: 161px;
                height: 161px;
                left: 5%;
                right: initial;
                top: 5%;
            }

            p{
                @extend .row;
                @extend .h-100;
                a{
                    @extend .align-self-center;
                }
            }
        }
    }

    .mgz-video-embed-play{
        img{
            width: 72px;
            height: auto;
        }
    }

    h1, .h1, h2, .h2, h3, .h3{
        font-family: "futura light bt";
    }

    .mgz-btn{
        color: $black;
    }
}

.custom-link{
    cursor: pointer;
    transition: all 400ms ease;
    &:hover{
        opacity: 0.9;
    }
}

.single-cate{
    @include media-breakpoint-down(lg) {
       // height: 200px;
    }
}

.slider--header{
    opacity: 0;
    height: 20px;
    transition: opacity 400ms ease;
    &.active{
        opacity: 1;
        height: auto;
    }

    @include media-breakpoint-down(lg) {
        display: block !important;
        flex-wrap: initial;
        .col-4{
            width: 100%;
        }
    }
}

.latest-products{
    .single-products{
        .title--product{
            @include media-breakpoint-down(lg) {
                min-height: 55px;
            }
        }
    }
}

.cms-index-index .mgz-element-single_image .mgz-single-image-wrapper{
    &::after{
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60%;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6965161064425771) 83%);
    }
}

.slider--reviews{

    .mgz-element-single_image .mgz-single-image-wrapper{
        &::after{
            content: '';
            width: initial;
            position: initial;
            bottom: initial;
            left: initial;
            height: initial;
            background: none;
        }
    }
    

}