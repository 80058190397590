
.inspiration--grid__image {
  height: 40vh;
  margin: 8px;
}

.inspiration--bottom__section { 
  margin-bottom: -5px;
  i {
    font-size: 60px;
    margin-bottom: -5px;
  }
  .default--link {
    border-color: $black;
  }
}
