button,
[type=button],
[type=reset],
[type=submit] {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
}
.btn-X{
    height:51px;
    line-height:51px;
}
.btn-L{
    height:59px;
    line-height:59px;
}
.pre-order-btn{
    width:100%;
    button{
        width:100%;
        height: 41px;
       background-color:rgba(0, 0, 0, 0.50);
       z-index:9;
       &.slick-disabled{
        display:none !important;
       }
    }
}
.filter-button{
    a{
        background-color:rgba(255, 255, 255, 0.10);
    }
}


.swash--button{
    @extend .text-decoration-none;
    @extend .text-center;
    @extend .bg-secondary;
    @extend .mt-2;
    @extend .text-extraDarkGray;
    @extend .articulat-n;
    @extend .fontXL;
    @extend .border-0;
    @extend .borderRadiusX;
    @extend .btn-L;
    @extend .d-inline-block;
    min-width: 200px;
    max-width: 100%;
}
