
.basket--wrap {
  .basket--title {
    .icon-shop {
      font-size: 40px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;
      @include animation(shakeThatBooty 5s ease-in-out infinite);
      animation-delay: 2s;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
    }
  }

  .basket--inner {
    font-size: 14px;

    .product--wrap {
      margin-bottom: 10px;

      .quantity--field {
        margin: 0;
        padding: 0;
      }

      .item--quantity__wrap {
        span {
          cursor: pointer;
        }
      }

      .product--name {
        text-transform: capitalize;
      }
    }

    img {
      max-width: 80px;
      margin-right: 20px;
    }

    input {
      display: inline-block;
      width: 25px;
      height: 25px;
      font-size: 12px;
      text-align: center;
      border: 1px solid rgba($black, 0.2);
      padding-right: 2px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .remove--item__cross {
      cursor: pointer;
      @include generate-transition (all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .font--small {
    &.basket--title {
      opacity: 0.5;
    }
  }
}

.total--row {
  font-size: 12px;
  vertical-align: middle;

  span {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.basket--bottom__row {
  margin-bottom: 20px;

  button {
    border: none;
    background: transparent;

    &.default--link {
      border-top: 28px;
    }
  }

  .default--link {
    border-color: $black;
    margin-right: 20px;

    > span {
      border-bottom: 1px solid;
    }
  }
}

.product-image-list{
  width: 100%;
  height: 200px;
}
