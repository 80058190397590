

.breadcrumbs-products{
    li{
        &:first-child{
            padding-left: 0 !important;
        }
    }
}

.slider-mini{
    .slick-slide{
        cursor: pointer;
    }
}

.price-box--product{
    .price-final_price .price{
        font-size: $fontLS;
    }
    .old-price{
        vertical-align: top;
        display: inline-block;
        padding-top: 12px;
            .price{
                font-size: $fontSX;
            }
    } 
}

.list--product__features{
    ul{
        @extend .ps-0;
        @extend .pb-0;
        @extend .mb-4;
        li{
            @extend .list-none; 
            @extend .text-white; 
            @extend .articulat-l; 
            @extend .fontXL; 
            @extend .align-items-center; 
            @extend .mb-3; 
            @extend .lh-1;
            display: block;
            position: relative;
            padding-left: 30px;
            &::before{
                @extend .me-2;
                content: '';
                background: url('/static/frontend/TheCurious/BCMW/en_GB/images/products/tick.svg') center/contain no-repeat;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 0;
                left: 0;
                margin-top: -2px;
            }
        }
    }
}
#popup{
    z-index: 11;
}

#offer-lable{
    z-index: 10;
    cursor: pointer;
    &:hover{
        opacity: 0.5;
    }
}

.category-view, .cate-info-section, .product-description{
     a{
        color: $white !important;
        &:hover{
            opacity: 0.5;
        }
    }
    span{
        color: $white !important;
    }
}

.product-description, .list--product__features{
    a{
       border-bottom: 1px solid $white;
        &:hover{
            opacity: 0.5;
        }
    }
}



#product-options-wrapper{
    padding: 10px 0px 0px;
    @extend .articulat-n;


    .label{
        margin-bottom: 5px;
        @extend .fontXL;
    }

    select{
        padding-left: 5px;
        color: $black;
        @extend .borderRadiusX;

        option{
            color: $black
        }
    }
}


.search.results{
    .right-section{
        width: 100%;

        .single-products{
            margin-bottom: 60px;
            position: relative;
        }
    }
}

.single-products{
    a{
        @include media-breakpoint-up(lg) {
            &:hover{
                opacity: 0.8;
            }
        }
    }
}

.category-view{
    p{
        margin-bottom: 1rem;
    }
}

.custom-price-label{
    // vertical-align: top;
    // display: inline-block;
    // margin-top: 11px;
    // line-height: 15px;
}

.trust-pilot-logo-product{
    width: 200px;
    height: auto;
    max-width: 100%;
}

.note{
    font-size: $fontXS;
}

#triggerMobileFilters{
    .icon--cross{
        width: 15px;
        transform: rotate(45deg);
    }
}

#super-product-table{
    background: $grey--darkest;

    th, td{
        background: $grey--darkest;
        color: $white;
    }

    .product-item-name, .stock{
        color: $white;
    }
}

.labels--gallery{
    z-index: 10;
    margin: 10px 30px 0 0;
}

.products-img-section{
    .labels--gallery{
        
        label{
            @include media-breakpoint-down(lg) {
                font-size: 12px;
                padding: 12px 10px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .labels--gallery{
            display: none !important;
        } 
    }
}


.main-slider{
    opacity: 0;
    transition: all 400ms ease;
    &.active{
        opacity: 1;
    }
    @include media-breakpoint-down(lg) {
        .slick-dots{
            text-align: center;
        }
    }
  }

#product-options-wrapper{
    .field{
        margin-bottom: 10px;
    }

    .product-custom-option{
        width: 90%;
        @include media-breakpoint-down(lg) {

        }
    }
}


.bulk--discount{
    border-radius: 10px;
    overflow: hidden;
    .top{
        background: #4e4e4e;
        color: $white;
        padding: 5px 0px;
        border-radius: 10px 10px 0px 0px;
    }
    .bottom{
        background: rgba(217, 217,217, 0.5);
        color: $white;
        border-radius: 0px 0px 10px 10px;
    }
}


.main-slider{
    @include media-breakpoint-up(lg) {
        a{
            &:hover{
                opacity: 0.8;
            }
        }
    }
}


.banner--product__list{
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    font-size: 12px;
    background: rgba(0,0,0,0.7);
    padding: 8px;
    text-align: center;
    @include media-breakpoint-down(lg) {
        padding: 4px;
    }
}

.ask-form-container{
    textarea, input[type="email"], input[type="text"]{
        @extend .bg-white;
        @extend .form-simple;
        @extend .border-0;
        @extend .din-r;
        @extend .w-100;
        @extend .fontXX;
        @extend .text-extraLightBlack;
        @extend .py-3;
        @extend .ps-3;
        @extend .mb-2;
        @extend .res14;
    }

    .submit{
        height: 50px;
        line-height: 50px;
    }
}

body .product-section .right-section .images--square{
    .products-img{
        aspect-ratio: 1 / 1 !important;
        height: auto !important;
    }
}

body .product-section .right-section .category-custom-height{
    .products-img{
        @include media-breakpoint-down(lg) {
            height: 300px !important;
        }
    }
}


.contain_images_and_white_background{
    .products-img{
        background: $white;
        img{
            object-fit: contain;
        }
    }
}


.bg--grey__new{
    background: #414141;
  }
  
