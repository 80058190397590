

.thank-you{
    z-index: 10;
}

.logo--thankyou{
    top: 40px;
    left: 40px;
    width: 154px;
    height: auto;
    img{
        width: 100%;
        height: auto;

    }
}

.text--thankyou{
    z-index: 11;
}

.close--thankyou{
    top: 40px;
    right: 40px;
    z-index: 12;
}