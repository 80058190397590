
.about--top__section {
  .row {
    height: 75vh;
  }
  height: 75vh;
  color: $white;
}



.about--text__section {
  padding-top: 120px;

  .icon-book-about {
    font-size: 40px;
    display: inline-block;
    transform: rotate(-10deg);
    margin-bottom: 25px;
    @include animation(shakeThatBooty 5s ease-in-out infinite);
    animation-delay: 4s;

  }

  .heading--large {
    font-size: 38px;
  }

  font-size: 14px;
  line-height: 1.5;
}

.block--about{
  .section--title{
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &::after{
      left: 0;
      margin-left: 0;
    }
  }
}
