// 10% OFF MODAL CSS
.off-modal {
    max-width: 615px;
    z-index: 1;
    margin-right: 56px;
    @include media-breakpoint-down(lg) {
        margin-right: 0;
        height:100%;
        max-width:100%;
        overflow-y: auto;
        overflow-x: hidden;
        z-index:9999 !important;
        top:0;
        background-color:#fff;
        .offer-content{
            padding-left:36px;
            padding-right:36px;
        }
    }

    .off-modal-inner {
        border-radius: 7px;
        .offer-img {
            height: 280px;
            @include media-breakpoint-down(lg) {
                height:415px;
            }
        }
        input {
            height: 55px;
            background-color: rgba(0, 0, 0, 0.04);
            @include media-breakpoint-down(lg) {
                height:71px;
            }
            &::placeholder {
                color: $extraLightBlack;
                opacity: 0.35;
            }
        }
        .input-btn {
            width: 51px;
            height: 45px;
            top: 7px;
            right: 6px;
            @include media-breakpoint-down(lg) {
                top:13px;
                right:8px;
            }
        }
    }
}
// DELIVERY MODAL CSS
.delivery-modal {
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.28);
    .inner-modal {
        max-width: 566px;
        background-color: #F8F8F8;
        overflow-y: auto;
        padding: 40px 40px 50px 62px;
        @include media-breakpoint-down(lg) {
            padding:48px 36px 50px 36px;
        }
    }
}
// ADD PRODUCTS MODAL CSS
.add-product-modal {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.28);
    .inner-modal {
        max-width: 452px;
        background-color: #F8F8F8;
        overflow-y: auto;
        padding: 40px 40px 70px 60px;
        @include media-breakpoint-down(lg) {
            padding:48px 36px 50px 36px;
            background-color:#ffffff !important;
            button{
                height:66px;
                &.view-bag-btn{
                    border:1px solid #1A191C !important;
                }   
            }
        }
        .cart-img {
            width: 88px;
            min-width: 88px;
            height: 86px;
            @include media-breakpoint-down(lg) {
                width: 96px;
                min-width: 96px;
                height: 96px;
            }
        }
        .add-img{
            height:240px !important;
                @include media-breakpoint-down(lg) {
            height:270px !important;
            }
        }
    }
}
// SEARCH LIST CSS

.search-list {
    //display: none;
    // max-width: 785px;
    // left: 50%;
    // margin: 0 auto;
    // top: 78px;
    // transform: translate(-50%, 78px);
    // box-shadow: 0px 0px 16px #0000002F;
    // border-radius: 15px;
    // padding: 20px 27px 30px 27px;
    // z-index: 999;
    
    // @include media-breakpoint-down(lg) {
    //     transform: translate(-50%, 0);
    //     border-radius: 0 !important;
    //     padding:14px 15px 200px !important;
    //     max-width:100% !important;
    //     box-shadow:none !important;
    //     top:220px !important;
    //     height:calc(100vh - 230px);
    //     overflow-y: auto;
    // }
    
    .close-btn{
        z-index:9;
        margin-top:20px;
    }
  
    .internal-search{
        &::placeholder{
            color:$extraLightBlack !important;
        }
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }
    .card-row {
        margin: 0 -12px;
        @include media-breakpoint-down(lg) {
            margin: 0;
        }
        .single-card {
            padding: 0 12px;
            margin-bottom: 36px;
            @include media-breakpoint-down(lg) {
                padding:18px 0;
                margin-bottom: 0;
                border-bottom: 1px solid rgba(112, 112, 112, 0.09);
            }
        }
        .pro-img{
            height:129px !important;
            @include media-breakpoint-down(lg) {
                width:75px !important;
                height:75px !important;
            }
        }
    }
}
// ORDER COMPLETED MODAL CSS
.order-complete-modal{
    z-index:99;
    @include media-breakpoint-down(lg) {
        overflow-y: auto;
        height:100% !important;
        overflow-x: hidden;
        .modal-img{
            height:400px !important;
        }
        .modal-content-main{
            height: 100% !important;
            .modal-content{
                transform: translate(0) !important;
            }
        }
    }
    .close-btn{
        z-index:99;
    }
}
// OFF TABLE CSS MODAL
#offer-lable{
    @include media-breakpoint-down(lg) {
        p{
            color:#fff;
            font-family: "ArticulatCF-Light";
        }
    }
}


.modal-inner-wrap{
    color: $black;
}