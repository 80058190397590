
.products--page__filter--banner {
  background: $green-dark;
  padding: 40px 0px;
  color: $white;

  .padding-top--20px {
    padding-top: 10px;
  }

  .title {
    opacity: 0.5;
  }

  select {
    border: 1px solid $white;
    background: none;
    -webkit-appearance: none;
    width: 100%;
    color: $white;
    border-radius: 0px;
    padding-left: 10px;
    margin-top: 10px;
    background-image: url('../images/arrow-white.svg');
    background-size: 12px;
    background-position: top 17px right 15px;
    background-repeat: no-repeat;

    option {
      background-color: $green-dark;
    }
  }

  #slider-range {
    margin-top: 22px;
    margin-left: 12px;
  }

  .ui-slider-handle {
    border-radius: 50%;
    background: $white !important;
    border: none !important;
    width: 12px !important;
    height: 12px !important;
  }

  .ui-slider-horizontal {
    height: 0.1em;
    background: $white;
    border: none !important;

    .ui-slider-range {
      background: $white;
      border: none !important;
    }
  }

  #amount {
    background: none;
    border: none;
    color: $white !important;
    font-size: 14px;
    font-weight: $regular;
    margin-top: 10px;
  }

  .default--link {
    span {
      color: $white;
    }
  }
}

.product--image__hover{
  position: relative;
}
 .product{
    @media (min-width:1000px) {
      &:hover{
          .img--wrap{
              img{
                  transform: unset !important;
                  
              }
          }
          .product--hover__overlay {
            visibility: visible;
            opacity: 1;
            
          }
      }
    }
    &.active {
      .product--hover__overlay {
        visibility: visible !important;
        opacity: 1 !important;
      } 
    }
  }
//@media (min-width: 1200px) {
    .product--top {
        .product--hover__overlay {
            opacity: 0;
            visibility: hidden;
            transition: all 500ms ease;
            background: #f5f5f5;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            
        }
        .product--image__hover {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            mix-blend-mode: darken;
        }
    }
    
//}

.products--display__wrap {
  position: relative;

  .filter--sticky {
    position: absolute;
    left: 0px;
    top: 0px;
    color: $white;
    background: $pink;
    transform: rotate(-90deg);
    padding: 20px 60px;
    font-weight: $regular;
    display: inline-block;
    margin-left: -55px;
    margin-top: 104px;
    z-index: 1;
    cursor: pointer;
    @include generate-transition (all, 500ms, ease);

    &:hover {
      opacity: 0.8;
    }

    .filter--sticky__button {
      white-space: nowrap;
    }
  }

  .filter--sticky__full {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: initial !important;
    background: $pink;
    display: inline-block;
    max-width: 18vw;
    transform: rotate(0deg);
    margin-top: 50px;
    z-index: 2;
    padding: 20px 20px;
    color: $white;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .close__full {
      cursor: pointer;
      @include generate-transition (all, 500ms, ease);
      margin-right: -20px;

      &:hover {
        opacity: 0.5;
      }
    }

    .default--link {
      color: $white;
    }

    .title {
      opacity: 0.5;
      margin-top: 5px;
    }

    select {
      border: 1px solid $white;
      background: none;
      -webkit-appearance: none;
      width: 100%;
      color: $white;
      border-radius: 0px;
      padding-left: 10px;
      margin-top: 10px;
      background-image: url('../images/arrow-white.svg');
      background-size: 12px;
      background-position: top 17px right 15px;
      background-repeat: no-repeat;
      margin-bottom: 10px;

      option {
        background-color: $pink;
      }
    }

    #slider-range {
      margin-top: 22px;
      margin-left: 12px;
    }

    .ui-slider-handle {
      border-radius: 50%;
      background: $white !important;
      border: none !important;
      width: 12px !important;
      height: 12px !important;
    }

    .ui-slider-horizontal {
      height: 0.1em;
      background: $white;
      border: none !important;

      .ui-slider-range {
        background: $white;
        border: none !important;
      }
    }

    #amount {
      background: none;
      border: none;
      color: $white !important;
      font-size: 14px;
      font-weight: $regular;
      margin-top: 10px;
    }

    .default--link {
      font-size: 16px;
    }
  }


  .product--wrap {
    margin-bottom: 60px;
    position: relative;
    @include generate-transition (all, 500ms, ease);

    &:hover {
      opacity: 0.5;
    }

    .product--image {
      height: 40vh;
    }

    &__icon{
      position: absolute;
      width: 64px;
      height: 64px;
      top: 0;
      right: 0;
      margin: 20px -10px 0px 0px;
    }

    .product--name {
      text-transform: capitalize;
    }

    .default--link {
      border-color: $black;
    }
  }
}

.product--page__banner {
  height: 40vh;

  .row {
    height: 40vh;
  }

  .heading--medium {
    color: $white;
    margin-bottom: 10px;
  }
}

.toolbar.toolbar-products {
  //display: none;
  width: 100%;
}

.slick-prev:before, .slick-next:before{
  display: none;
}

body .products-related{
  color: $blue;
  .product-name{
    height: auto;
    line-height: initial;
    padding-top: 5px;
  }

  .part-number{
    color: $blue;
  }
  img{
    width: 150px;
    height: auto;
  }
}

.products.products-grid, .custom-slider{
  padding-bottom: 0;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  background: none;
  border: 0;
}

.nav-tabs>li{
  border: 0;
}


.priceBox--productPage{
   .special-price .price:before,
   .old-price .price-wrapper:before{
     display: none;
   }
}
