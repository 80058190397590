// LATEST PRODUCT SLIDER CSS
.latest-product-slider {
    img {
        height: 650px !important;
        @include media-breakpoint-down(lg) {
            height: 415px !important;
        }
    }
    .slick-dots {
        display: flex;
        align-items: center;
        transform: rotate(90deg);
        width: auto;
        bottom: 50%;
        right: 80px;
        z-index: 1;
        @include media-breakpoint-down(lg) {
            display: block;
            transform: rotate(0deg);
            padding: 0;
            width: 100%;
            right: 0;
            bottom: 62px;
        }
        li {
            height: 10px;
            width: 10px;
            background-color: $white;
            opacity: 0.33;
            border-radius: 50%;
            button {
                // height: 10px;
                // width: 10px;
                display: none;
                &::before {
                    display: none !important;
                }
            }
            &.slick-active  {
                width: 14px !important;
                height: 14px !important;
                opacity: 1 !important;
                vertical-align: middle;
            }
        }
    }
}
// PRODUCTS SLIDER CSS
.proslider {
    .slick-list {
        overflow: visible;
        .slick-slide {
            padding: 0 8px;
            vertical-align: top;
        }
    }
}
// HELPFULL SLIDER CSS
.helpfull-section {
    .slider-img {
        height: 451px;
        @include media-breakpoint-down(lg) {
            height: 381px;
        }
    }
}
// REVIEW SLIDER CSS
.review-slider {
    .slick-list {
        margin: 0 -8px;
        @include media-breakpoint-down(lg) {
            overflow: visible;
        }
    }
    .slick-slide {
        padding: 0 8px;
    }
}
// INSTAGRAM SLIDER CSS
.instagram-section {
    .slick-list {
        margin: 0 -8px;
        @include media-breakpoint-down(lg) {
            margin: 0 -3px;
        }
    }
    .slick-slide {
        padding: 0 8px;
        @include media-breakpoint-down(lg) {
            padding: 0 3px;
        }
    }
}
// INTERESTED SLIDER CSS
.interested-section {
    .slider-img {
        height: 451px;
        @include media-breakpoint-down(lg) {
            height: 250px;
        }
    }
}
// MINI SLIDER CSS
.slider-mini {
    height: 600px;
    width: 160px !important;
    .slick-list {
        overflow: visible;
    }
    .slick-track{
        margin-top: -8px;
    }
    
    .slick-slide {
        padding: 8px 0;
        border: none !important;
    }
    .slider-img {
        height: auto !important;
    }
    .slick-current {
        .slider-img {
            border: 2px solid #fff !important;
            position: relative;
            &::after {
                position: absolute;
                content: '';
                display: block;
                opacity: 0.29;
                background-color: #000;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
    }
}
// MAIN SLIDER CSS
.main-slider {
    width: calc(100% - 180px);
    @include media-breakpoint-down(lg) {
        width: 100%;
        .slick-dots {
            height:10px;
            bottom:-40px;
            margin-top: 20px;
            display: block !important;
            
            li {
                height: 10px;
                width: 10px;
                background-color: $white;
                opacity: 0.33;
                border-radius: 50%;
                display: inline-block;

                button {
                    // height: 10px;
                    // width: 10px;
                    display: none;
                    &::before {
                        display: none !important;
                    }
                }
                &.slick-active {
                    width: 14px !important;
                    height: 14px !important;
                    opacity: 1 !important;
                    vertical-align: middle;
                }
            }
        }
    }
    .slider-img,
    .slick-slide {
        height: auto;
        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }
}


// OTHER RANGE SLIDER CSS
.other-range-slider {
    .slick-list {
        margin: 0 -8px;
        @include media-breakpoint-down(lg) {
            overflow: visible;
        }
    }
    .slick-slide {
        padding: 0 8px;
    }
}
// REALTED PRODUCTS SLIDER CSS
.related-products {
    .slick-list {
        margin: 0 -8px;
        overflow: visible;
    }
    .slick-slide {
        padding: 0 8px;
    }
}
// BENEFITS SLIDER CSS
.benefits-slider {
    .slick-list {
        overflow: visible;
    }
    .slick-slide {
        padding: 0 16px;
    }
}
// BLOG  SLIDER CSS
.slider-blog {
    .slick-list {
        overflow: visible;
        margin: 0 10px;
    }
    .slick-slide {
        padding: 0 4px;
    }
}
// HELPFULL SLIDER CSS
.helpfull-slider {
    @include media-breakpoint-down(lg) {
        .slick-list {
            overflow: visible;
        }
    }
}
// AWARD SLIDER CSS
.award-slider {
    opacity: 0;
    height: 130px;
    transition: opacity 400ms ease;
    &.active{
        height: auto;
        opacity: 1;
    }

    @include media-breakpoint-down(lg) {
        .slick-list {
            overflow: visible;
        }
    }
}
// WILD MOBILE SLIDER CSS
.slider-mobile-wild{
    .slick-list {
        overflow: visible;
        margin: 0 -15px;
    }
    .slick-slide {
        padding: 0 15px;
        .cate-img{
            height:120px;
        }
    }
}

.small-slider-content{
    .slick--next{
        margin-bottom: 8px;
    }
}