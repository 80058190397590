



.products--display__wrap {
  .container-fluid{
    margin-right: -15px;
  }
}

.categorySlider{
  opacity: 0;
}



.related{
  .products--display__wrap .product--wrap__icon{
    margin-top: 0;
  }
}


.product-items{
  list-style: none;
  padding: 0;
}

.product-item-info{
  // position: relative;
  // margin-bottom: 20px;
  // text-align: center;
  // -webkit-box-shadow: 0 0 10px 0 #cccacc;
  // -moz-box-shadow: 0 0 10px 0 #cccacc;
  // box-shadow: 0 0 10px 0 #cccacc;
  // padding: 12px;
  // background: #fff;
}


.product--block__price{
  font-weight: 700;
  margin-top: 10px;
}

.product--block__image{
  margin-bottom: 10px;
  position: relative;
  display: block;
}



.filter-options{
  ol{
    list-style: none;
    margin: 0;
    padding: 0;
  }
}


.sidebar--title{
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 30px;
  position: relative;
  font-weight: 700;

  &::after{
    content: '';
    position: absolute;
    bottom: 18px;
    left: 0;
    width: 50%;
    background: $grey--darkest;
    height: 1px ;
  }
}



.filter-options > div{
  margin-bottom: 20px;
}


.label--clearAll{
  font-size: 12px;
}


.category-trek{
  .category--full-img{
    .overlay--orange, .vertically-middle{
      display: none;
    }
  }
}

.category--full-img h1{
  font-family: $sans-serif;
  font-size: 48px;

}

.category--full-img{
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  color: $white;
  p{
    width: 60%;
    margin: 0 auto;
    max-width: 600px;
  }

  h1{
    color: $white;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 40px;
  //  font-family: $sans;

    &::after{
      color: $white;
      height: 4px;
      background: $white;
      width: 40px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      margin-bottom: -20px;
      content: '';
    }
  }
}


.pagination-sm .page-item:first-child .page-link{
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.toolbar.toolbar-products{
  margin-top: 40px;
}

.page-products{
  .product--block__price{
    padding-top: 0;
  }
}

.block-layered-nav {
  color: #1D184C;
  text-align: left;
  a, .price{
    color: #1D184C;
    text-align: left;
    font-weight: normal;
  }

}

.two-columns-left-fix{
  .col-main{
    float: none !important;
  }
}

.products-grid .actions .register_interest_button{
  line-height: 26px;
}

body .products-grid .price-box .special-price{
  display: inline-block;
}

.products-related{
  .price-box{
    margin-left: 5px;
  }
}

.amxnotif-container .register_interest_button{
  width: 100%;
  a{
    display: block;
  }
}

.our-review-lable {
  display: none !important;
}

body .notification-container{
  display: block;
  .actions-toolbar .primary{
    float: none;
    margin-left: 0;
  }
}


.text--preorderSmall{
  font-size: 11px;
}

.select2-container--default .select2-results > .select2-results__options{
  max-height: 400px;
}

.secondary-notify-button{
  display: none !important;
}

.catalogsearch-result-index{
  .secondary-notify-button{
    display: block !important;
  }
}

.catalogsearch-result-index .amxnotif-container .register_interest_button{
  display: none;
}

.products-grid .price-box{
  display: inline-block;
  min-height: 50px;
  margin: 0 !important;
}

body .filter-options-content{
  label{
    font-weight: 300;
    color: $blue;
    text-transform: initial;
  }

  .filter-search{
    margin: 10px 0px;
    font-size: 12px;
    &::placeholder{
      font-size: 12px;
    }
  }
}




.catalogsearch-result-index{
  .price-label{
    &:after{
      display: none;
    }
  }
}


.smile-es-range-slider{
  div[data-role="from-label"], div[data-role="to-label"]{
    display: inline-block;
  }

  div[data-role="to-label"]{
    float: right;
  }

  .action.primary.small{
    border: 1px solid $grey;
    padding: 3px 5px 2px;
  }

  .actions-toolbar > div{
    display: initial;
  }
}

.category--image{
  &__preorder, &__mto{
    position: absolute;
    top: -12px;
    left: -12px;
    z-index: 3;
    width: 50% !important;
    height: auto !important;
  }
}


body{
  .products-grid .product-name{
    height: 40px;
    line-height: initial;
  }

  .products-grid .price-box{
    min-height: auto;
    height: 30px;
  }
}

.custom-label-related{
  display: none;
}

.products-upsell{
  .custom-label{
    display: none;
  }


  .custom-label-related{
    display: inline-block;
  }
}

body .page-wrapper{

  .products-related, .block.upsell{
    .old-price .price-wrapper:before, .price:before{
      display: none;
    }

    .special-price {
      display: inline-block !important;
    }

    .old-price{
      display: inline-block !important;
      .price{
        color: black !important;
      }
    }

    .custom-label-related{
      display: inline-block;
      color: $black;
      text-transform: uppercase;
      text-decoration: line-through;
      font-weight: bold;
      font-size: 13px;
    }
  }
}


.search.results{
  .block{
    display: none;
  }
}

.pill--list{
  @extend .articulat-l ;
  @extend .fontXM ;
  @extend .text-light ;
  @extend .me-2 ;
  @extend .ps-half;

  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  background-color: $grey--dark;
  padding: 7px 20px 5px;
  font-size: 12px;

  &__static{
    top: initial;
    right: initial;
  }

  @include media-breakpoint-down(lg) {
    padding: 7px 16px;
    font-size: 12px;
  }

}

.home-category{
  p{
    color: $grey;
  }
  a{
    font-weight: 700;
    color: $white;
    opacity: 1;
  }
}

.cate-info-section{
  p, img{
    margin-bottom: 1rem;
  }
}

.font--links__category{
  @include media-breakpoint-down(lg) {
    a{
      font-size: 16px;
    }
  } 
}

.smile-es-range-slider{
  .ui-slider{
    margin-top: 15px;
  }

  .action{
    cursor: pointer;
  }
}