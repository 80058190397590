.basket--bottom__text--wrap {
  .col-lg-6 {
    display: inline;
  }
}

.payment-method {
  margin-bottom: 30px;
}

.checkout--left__wrap {
  padding-left: 100px;

  .page--checkout__position--wrap {
    position: relative;
  }

  .circle--checkout__position {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: $white;;
    border: 1px solid #1d174c;
    text-align: center;
    display: inline-block;

    &.active {
      border: 1px solid transparent;
      opacity: 1;
      background: #1d174c;
    }
  }

  .heading--medium {
    margin-top: 20px;
    font-size: 28px;
  }

  .page--checkout__position {
    font-size: 12px;
    text-transform: capitalize;
    color: $navy;
    font-weight: 700;
    opacity: 0.2;

    &.active {
      opacity: 1;
    }
  }

  .checkout--login__title {
    font-size: 12px;
    text-transform: uppercase;
    color: $navy;
    font-weight: 700;
    letter-spacing: 1.5px;

    a {
      border-bottom: 1px solid $navy;
      @include generate-transition (all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .section--title {
    font-weight: 700;
    font-size: 18px;
  }

  .custom--checkbox__container {
    padding-right: 40px;
  }


  .continue--shopping__link {
    a {
      @include generate-transition (all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }

      .icon-arrow {
        display: inline-block;
        transform: rotate(180deg);
        margin-right: 15px;
        margin-bottom: 1px;
        vertical-align: middle;
      }
    }
  }
}

.checkout--right__wrap {
  padding-left: 60px;
  padding-right: 100px;
  font-size: 14px;
  font-weight: 700;

  .heading--medium {
    font-size: 28px;
    display: inline-block;
    margin-right: 10px;
  }

  .amend--order__link {
    font-size: 12px;
    border-bottom: 1px solid $navy;
    @include generate-transition (all, 500ms, ease);

    &:hover {
      opacity: 0.5;
    }
  }

  .summary--title {
    font-size: 12px;
  }

  .product--name, .product--description {
    font-size: 15px;
  }

  .per--month {
    font-size: 10px;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span.label {
    padding: 0 !important;
  }

  .item--quantity__wrap {
    span {
      font-size: 14px;
      display: inline-block;
      cursor: pointer;
      padding: 2px 5px;
      background: $white;
      border-radius: 5px;
      z-index: 1;
      position: relative;
      @include generate-transition (all, 500ms, ease);

      &:hover {
        background: $navy;
        color: $white;
      }
    }

    input[type="number"] {
      width: 25px;
      border: none;
      background: $white;
      text-align: center;
      font-size: 12px;
      margin: 0px -8px;
      padding: 4px 10px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .coupon--section__wrap {
    @include generate-transition (all, 500ms, ease);

    &.not--active {
      max-height: 0px;
      overflow: hidden;
    }
  }

  .coupon--code__wrap {
    background: $white;
    border-radius: 5px;

    .coupon--code__wrap--inner {
      padding: 5px 20px;
      font-size: 14px;
    }

    .icons-cross {
      float: right;
      vertical-align: middle;
      display: inline-block;
      font-size: 20px;
      margin-top: 2px;
      cursor: pointer;
      @include generate-transition (all, 500ms, ease);

      &:hover {
        opacity: 0.5;
      }
    }

    input {
      background: none;
      padding: 0;
    }

    button {
      position: absolute;
      top: 6px;
      right: 20px;
    }

    .button--remove {
      position: absolute;
      top: 14px;
      right: 30px;
      padding: 0px;
      height: 30px;
      width: 30px;
      background: none;
    }
  }

  .prices--total {
    font-size: 18px;
  }
}

.payment-method {
  .payment-method-content {
    display: none;

    .actions-toolbar {
      display: flex;

      .primary {
        margin: 0 auto;
        float: none;
      }
    }
  }

  &._active {
    .payment-method-content {
      display: block;
    }
  }
}

.table-checkout-shipping-method {
  width: 100%;
}

.table-checkout-shipping-method {
  tbody {
    .col-method,
    .col-price,
    .col-method-title {
      margin-left: 15px;
    }
  }
}

#opc-new-shipping-address {
  fieldset {
    &.street {
      margin-top: 20px;
    }
  }

  .form-shipping-address .field[name$="shippingAddress.telephone"] {
    margin-top: 0;
  }

  .form-shipping-address .field[name$="shippingAddress.region"],
  .form-shipping-address .field[name$="shippingAddress.postcode"] {
    width: 49%;
  }

  .form-shipping-address .field[name$="shippingAddress.country_id"] {
    width: 100%;
    display: block;
    padding: 10px 0 0 0;
  }
}




.field {
  &.street {
    .label {
      @extend .mb-2;
    }
  }

  &.addresses {
    @extend .mb-2;
  }
}

#discount-coupon-form {
  .section--title {
    font-size: 15px;
    font-weight: 700;
  }

  .button--remove {
    padding: 10px 28px;
    letter-spacing: 0;
  }
}


.subscription--bottom__banner--image {
  .heading--medium {
    display: inline;
  }

  .button--white {
    vertical-align: top;
  }
}

.cart-empty {
    a {
        color: #1982D1;
        text-decoration: none;
    }
    p {
        margin-bottom: 0;
    }
}

.checkout--banner {
  padding: 0 100px;
}

.checkout-billing-address {
  padding-bottom: 20px;
}



.checkout-success {
  text-align: center;
  margin: 200px 80px !important;
  margin-bottom: 40px;

  .table-wrapper .order-items{
    width: 50%;
    margin: 20px auto;

    @media (max-width: $md) {
      width: 90%;
    }
  }

  .primary {
    float: none;
  }

  .actions-toolbar {
    display: inline-block;
    .continue {
      display: inline-block;
      position: relative;
      padding: 25px 20px;
      text-align: center;
      border: none;
      background-color: transparent;
      font-size: 21px;

      span {
        position: relative;
        z-index: 10;
        padding: 0px 20px;
        text-align: center;
        display: block;
        margin-top: 5px;
      }


      &:hover {
        &:before {
          //clip: rect(0px 250px 100px 250px);
          opacity: 0.5;
        }
      }
    }
  }
}

.checkout-success {
  text-align: center;
  margin: 80px 80px;
  margin-bottom: 40px;
}

.checkout-onepage-success {
  #registration {
    text-align: center;
  }
}

.checkout--left__wrap {
  .page--checkout__position--line {
    background: $navy;
    position: absolute;
    width: 52%;
    height: 2px;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    margin-top: 9px;
  }

  .heading--medium {
    margin-top: 20px;
  }
}

.checkout-container {
  padding-top: 20px;
  .checkout--right__wrap {
    font-weight: $regular;
    strong {
      font-weight: $regular;
    }
  }
}

#block-discount-heading {
  cursor: pointer;
  @include generate-transition (all, 500ms, ease);
  &:hover {
    opacity: 0.5;
  }
}

.payment-option-inner {
  margin-top: 0px;
}

#discount-form {
  margin-right: -15px;
  .control {
    position: relative;
    .cancelInput {
      position: absolute;
      top: 40%;
      right: 15%;
      i {
        color: #E6E6E6;
        font-size: 18px;
      }
    }
  }
  #discount-code {
    width: calc(100% - 35px);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 43px;
    vertical-align: top;
  }
  button {
    display: inline-block;
    position: relative;
    height: 43px;
    width: 43px;
    text-align: center;
    border: none;
    background-color: #1d174c;
    margin-left: -15px;
    border-radius: 5px;
    vertical-align: top;
    margin-top: 8px;

    span {
      position: relative;
      z-index: 10;
      padding: 0px 20px;
      text-align: center;
      display: block;
      margin-top: 5px;
      color: $white;
    }

    &:before {
      content: "";
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-left: -5px;
      margin-bottom: -2px;
    }

    &:hover {
      &:before {
        //clip: rect(0px 250px 100px 250px);
        opacity: 0.5;
      }
    }
  }

  .actions-toolbar {
    text-align: center;
    .primary {
      margin-right: 0px;
      float: initial;
    }
  }
}

.price--overview  {
  clear: both;
  margin-top: 20px;
}

.shipping-address-items {
  .default--link {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


#checkout-loader.loading-mask {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 200;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  img {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -85px;
    margin-right: -85px;
  }
  p {
    margin-top: 60px;
  }
}

#checkout-loader .loader, .loading-mask .loader {
    width: auto;
    margin: auto;
    text-align: center;
}

.checkout-index-index {
  .loading-mask {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 200;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    p {
      margin-top: 60px;
    }
    img {
      position: absolute;
      top: 50%;
      right: 50%;
      margin-top: -110px;
      margin-right: -105px
    }

    .loader {
      min-width: 96px;
      min-height: 96px;
    }
  }
}


#checkout {
  .heading--medium {
    margin-bottom: 40px;
  }
  .summary--title {

  }

  #shipping-method-buttons-container, #checkout-step-shipping_method {
    button {
      float: initial;
      margin-left: 0px;
    }
  }
}

.checkout-success {
  .actions-toolbar {
    margin: initial;
    width: initial;
  }
}

#co-payment-form {
  margin-top: 20px;
}

.checkout--cart__totals {
  clear: both;
}

.billing-address-details {
    .action-edit-address {
      padding: 0px;
    }
}

.basket--items__text {
  .product--name {
    text-transform: capitalize;
  }
}

.button--empty{
  border: 0;
  background: none;
  text-transform: uppercase;

}

.cart{
  #coupon_code{
    width: calc(100% - 65px);
  }
}

mark, .mark{
  background: none;
}


.checkout-methods-items{
  display: none;
}



.mto-cart{
    background-color: #d7ffd7;
    float: left;
    padding: 5px 10px;
    color: green;
    border: 1px solid #fefffe;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 20px;
}

#shopping-cart-table{
  .table-headings{
    font-weight: bold;
  }
}

#checkout{
  .col-lg-4{
    padding: 0 7.5px;
  }
}

.amcheckout-step-container .amcheckout-summary-container .table-totals .grand .mark, .amcheckout-step-container .amcheckout-summary-container .table-totals .grand .amount{
  padding: 2px 5px;
}

.amcheckout-main-container .table-totals th, .amcheckout-main-container .table-totals td{
  padding: 2px 5px;
}

.totals-tax{
  display: none;
}


.payment-method-content{
  .card-container{
    background: none;
    border: 0;
    padding: 5px;
    iframe{
      height: 100%;
      padding: 7px;
    }

    input{
      line-height: 27px;
      padding: 6px;
      &::placeholder{
        color: $black;
        font-size: 12px;
      }
    }
  }

  .mollie-component{
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    height: 30px;
  }
} 