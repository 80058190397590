

@include media-breakpoint-down(lg) {

  .header--menu{
    .level0{
      &>a{
        position: relative;
      }
    }
  }
  

  a, button{
    &::after {
      content: " pseudo element";
      opacity: 0;
      display: none;
    }
  
    &:hover::after {
      opacity: 1;
    }
    
    &:hover{
      opacity: 1;
    }
  }

  .custom-link{
    &:hover{
      opacity: 1 !important;
    }
  }

 

  .mgz-element-column > .mgz-element-inner{
    padding: 0;
  }

  .mgz-child:not(:last-child) > .mgz-element-inner{
    margin-bottom: 0;
  }

  .icons-row .mgz-container{
    .slick-slide{
      padding: 0px 10px;
    }
  }

  .cart-btn .badge{
    display: block;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 0;
    line-height: 16px;
    text-indent: 1px;
  }

  .header-fixed{
    .logo-link{
      display: none;
    }

    #search-toggle-card{
      display: none !important;
    }
  }

  .dimension-section{
    .nav{
      display: block;
    }
  }

  .basket-section{

    .quantity--field{
      text-align: center;
    }

  }

  .basket--qty__control{
    width: 20px;
    height: 20px;
    background: $secondary;
    border-radius: 50%;
    line-height: 20px;
    color: $white;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    font-family: $futuraM;
    &.minus{
      line-height: 18px;
    }
  }

  .checkout-index-index{
    .page-main.container{
      padding: 0 15px;
    }
  }

  .login-container{
    padding-left: 15px;
    padding-right: 15px;

    .block-customer-login{
      padding-bottom: 40px;
    }
  }
  
  // .filter-menu{
  //   display: none;
  // }

  .slider--header{
    text-align: center;
  }

  .header-fixed .serach-cart{
    position: absolute;
    width: calc(100% - 140px);
    top: 50%;
    right: 58px;
    margin-top: -50px;
    margin-right: 10px;
  }

  body .subscription--popup{
    width: 80%;
    .actions-toolbar{
      text-align: center !important;
    }
  }

  .filter-menu{
    position: fixed;
    top: 0;
    right: 0;
    background: $black !important;
    height: 100vh;
    z-index: 2000;
    padding: 120px 20px 60px 20px;
    overflow-y: scroll;
    display: none;
    width: 100%;

  
    &.active{
        display: block;
  
        .trigger--mobileClear{
            display: block;
        }
    }
  
    .trigger--mobileClear{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 15px;
        text-align: center;
        z-index: 50;
        background: $white;
        -webkit-box-shadow: 0px 10px 20px 12px rgba(0,0,0,0.36); 
        box-shadow: 0px 10px 20px 12px rgba(0,0,0,0.36);
        display: none;
    }
  
    .filter--custom{
        flex: initial;
        width: 100%;
  
        &__heading{
            font-size: $fontM;
            padding-bottom: 20px;
            border-bottom: 1px solid $grey;
            margin-bottom: 30px;
            display: block;
            &:after{
                font-family: 'icomoon' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e90a";
                font-size: 18px;
                color: $grey;
                position: absolute;
                margin-top: 7px;
                right: 22px;
                display: inline-block;
                transform: rotate(90deg);
            }
        }
    }
  
    .filter--custom{
        &.active{
            .filter--custom__options{
                max-height: 1000em;
                padding-bottom: 20px;
            }
  
            .filter--custom__heading{
                &::after{
                    transform: rotate(-90deg);
                }
            }
  
        }
  
        .smile-es-range-slider{
            .ui-slider-horizontal{
                margin: 20px 13px;
            }
        }
  
        .action.primary.small{
            color: $white;
            background: $purple;
            border: 0;
        }
  
        input[type="checkbox"] + label:before{
            border: 1px solid $purple;
            width: 22px;
            height: 22px;
        }
  
        input[type="checkbox"]:checked + label:before{
            background: $purple;
        }
        
  
        .filter--custom__options{
            position: relative;
            background: $white;
            color: $blue;
            max-height: 0;
            overflow: hidden;
            top: 0;
            left: 0;
            padding: 0 0 0px 0;
            li, a{
                color: $blue;
                font-size: 21px;
            }
        }
    }
  
  
  }

  .section-overlay{
    .mgz-col-md-10{
      width: 75%;
    }
    .mgz-col-md-2{
      width: 25%;
    }
  }

  .award-slider .slick-slide{
    padding: 0 15px;
  }
  
}

@media (min-width: 720px) and (max-width: 1100px) {
   ul.header--menu .sub-menu {
    //padding-left: 30px !important;
    //max-height: calc(100vh - 230px);
    //overflow-x: scroll;
  }

  body{
    .catagory-category .category-list .cate-img, .single-cate{
      height: auto !important;
    }
  }

  .header--menu .sub-menu .sub-menu-block p img{
    max-width: 300px;
  }

}

@include media-breakpoint-up(lg) {

  .position-lg-relative{
    position: relative;
  }

}

.open-menu-icon{
  display: none;
}


@media (min-width: 720px) and (max-width: 1200px) {

  header .header .full-logo{
    margin-left: 40%;
  }
}

