.card-hover {
    overflow: hidden;
    img {
        transform: scale(1);
        transition: 350ms all ease-in-out;
    }

    .image--main{
        position: relative;
        z-index: 2;
    }

    .image--rollover{
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: auto;
        z-index: 1;
        opacity: 0;
    }

    &:hover {
        
        @include media-breakpoint-up(lg) {
            img {
                transform: scale(1.1);
            }
            
            

            // .image--main{
            //    //margin-left: calc(-100% - 30px);
            //    opacity: 0;
            // }

            // .image--rollover{
            //     opacity: 1;
            // }
        }
    }

}
// SINGLE PRODUCTS CARD
.single-products{
    .products-img{
        height: 400px;
        @include media-breakpoint-down(lg) {
            height:260px;
        }
    }
    button{
        padding:9.5px;
        
        &:last-child{
            border:1px solid $white !important;
        }
    }
}
// SINGLE CATEGORY CARD
.single-category{
    .cate-img{
        height:350px;
    }
}
