body.account {
  .block {
    margin-top: 15px;
  }

  .block-content {
    margin-left: 0;
    margin-right: 0;
  }

  .fieldset {
    display: inline-block;
    width: 100%;

    .field {
      width: 49%;
      display: inline-block;
      margin-bottom: 15px;
      vertical-align: top;

      &:nth-child(2) {
        float: right;
      }
    }
  }
}

//.login-container, .customer-account-forgotpassword {
.actions-toolbar {
  margin: 15px 0 0;
  width: 100%;

  > div {
    display: inline-block;
  }

  .primary {
    margin-left: 10px;
    float: right;
  }

  .secondary {
    line-height: 45px;
  }

  .primary, .secondary {
    display: inline-block;
  }

  .action {
    &.remind {
      color: $navy;
      font-size: 13px;
    }
  }
}

//}
.form-login {
  width: 100%;
  margin-right: 40px;
  line-height: 14px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0px;
  }
}

.fieldset {
  &.login {
    width: 100%;
  }
}

.login-container {
  padding-bottom: 50px;
  padding-top: 0px;

  #login-form {
    #email {
      margin-bottom: 10px;
    }
    .actions-toolbar {
      margin-top: 0px;
    }
  }

  .block-content {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

.customer-account-forgotpassword {
  form.password {
    max-width: 400px;
    margin: 45px auto;
  }
}

.captcha-image {
  padding: 15px 0;
}

#block-customer-login-heading, #block-new-customer-heading {
  font-weight: normal;
}

form.account {
  padding: 20px 0 40px;
  .field {
    width: 49%;
    display: inline-block;

    &:first-of-type:not(.field-name-firstname) {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .confirmation {
    float: right;
  }

  .field-name-lastname {
    float: right;
  }
}

@media (max-width: 768px) {
  .account .block {
    width: 100%;
    display: block;
    margin-bottom: 20px;

    .box-title {
      padding: 10px 0 5px;
      position: relative;
      display: block;
    }

    .block-content {
      display: block;
      .box {
        display: inline-block;
        vertical-align: top;
        width: 100%;
      }
    }
  }
}

@media (max-width: 425px) {
  .account {
    .block-title {
      margin-top: 10px;
    }
    .col {
      &.shipping {
        display: none;
      }
      &.total {
        display: none;
      }
    }
  }
}

@media (max-width: 575.98px) {
  form {
    .field {
      width: 100% !important;
      float: none;
      margin-bottom: 10px !important;
    }
  }
}

.nested > .field {
  width: 100% !important;
}

//#form-address-edit {
//  @media (max-width: 575.98px) {
//    .field {
//      width: 100%;
//    }
//  }
//}


.form-create-account {
  padding-top: 60px !important;
  padding-bottom: 100px !important;

  .heading--medium {
    font-size: 16px;
  }

  .fieldset {
    margin-bottom: 40px;
  }

  #email_address {
    margin-bottom: 0px;
  }
  #password-strength-meter-container {
    font-size: 12px;
    opacity: 0.5;
  }
}

.customer-account-index, .sales-order-history, .wishlist-index-index, .customer-address-form, .customer-account-edit {
  .columns {
    &.row {
      &.mt-4 {
        &.mb-4 {
          padding-top: 40px;
          padding-bottom: 40px;
          .block-title {
            margin-top: 10px;
            margin-bottom: 20px;
            &.address--block {
              margin-top: 40px;
            }
          }
          .action {
            @include generate-transition (all, 500ms, ease);

            &:hover {
              opacity: 0.5;
            }
          }

          .block-dashboard-orders {
            margin-top: 40px;

            .table th, .table td {
              width: auto;
              .action {
                display: block;
              }
            }
          }

        }
      }
    }
  }
}

.sales-order-history, .wishlist-index-index {
  .message {
    margin-top: 10px;
  }
}

.field-select-billing {
  display: none;
}
