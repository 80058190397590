.checkbox-rounded {
    &.form-group-checkbox {
        label {
            &::before {
                content: '';
                -webkit-appearance: none;
                background-color: #fff;
                width: 13px;
                min-width: 13px;
                height: 13px;
                display: inline-block;
                border: 1px solid #1C1A49;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 50%;
                margin-right: 20px;
                @include media-breakpoint-down(lg) {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                }
            }
        }
        input:checked+label {
            &::after {
                content: "";
                display: block;
                left: 3px;
                position: absolute;
                top: 50%;
                transform: translate(0%, -50%);
                width: 7px;
                border-radius: 50%;
                min-width: 7px;
                height: 7px;
                background-color: $extraLightBlack;
                @include media-breakpoint-down(lg) {
                    top: 52%;
                    left:4px;
                    transform: translate(0%, -52%);
                }
            }
        }
        input:checked+.visa {
            &::after {
                @include media-breakpoint-down(lg) {
                    top: 51%;
                    left:4px;
                    transform: translate(0%, -51%);
                }
            }
        }
    }
    &.form-group-square {
        label {
            &::before {
                content: '';
                -webkit-appearance: none;
                background-color: #fff;
                width: 18px;
                min-width: 18px;
                height: 18px;
                display: inline-block;
                border: 1px solid #1C1A49;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 12px;
                border-radius: 5px;
            }
        }
        input:checked+label {
            &::after {
                content: "";
                display: block;
                left: 6px;
                position: absolute;
                top: 3px;
                width: 6px;
                height: 10px;
                border: solid #1C1A49;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                @include media-breakpoint-down(lg) {
                    top:6px;
                }
            }
        }
    }
}