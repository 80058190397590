.popup-authentication .modal-inner-wrap {
  min-width: 768px;
  width: 60%;
}

.popup-authentication .block-authentication {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  .block {
    padding-bottom: 50px !important;
  }

  .block-title {
    padding-bottom: 15px;
  }
}

.popup-authentication .block[class],
.popup-authentication .form-login,
.popup-authentication .fieldset,
.popup-authentication .block-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.popup-authentication .block[class] {
  box-sizing: border-box;
  padding: 10px 30px 0 0;
  width: 50%;
}

.popup-authentication .block[class] + .block {
  border-top: 0;
  margin: 0;
  padding: 10px 0 0 40px;
}

.popup-authentication .block[class] + .block::before {
  left: 0;
  top: 50%;
}

.popup-authentication .actions-toolbar {
  margin-bottom: 0;
  margin-top: auto;
}

.modal-popup {
  z-index: 1060;
  left: 0;
  overflow-y: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &._show {
    visibility: visible;
  }
}

.modal-popup._show .modal-inner-wrap {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.modal-popup {
  z-index: 1500 !important;
  .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    transition-duration: .2s;
    -webkit-transition-property: -webkit-transform, visibility;
    transition-property: transform, visibility;
    transition-timing-function: ease;
    border: 1px solid grey;

    .modal-header {
      position: relative;
    }
  }
}

.modal-popup._inner-scroll {
  overflow-y: visible;
}

.ie10 .modal-popup._inner-scroll,
.ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}

.modal-popup._inner-scroll .modal-inner-wrap {
  max-height: 90%;
}

.ie10 .modal-popup._inner-scroll .modal-inner-wrap,
.ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}

.modal-popup._inner-scroll .modal-content {
  overflow-y: auto;
}

.modal-popup .modal-header,
.modal-popup .modal-content,
.modal-popup .modal-footer {
  padding-left: 3rem;
  padding-right: 3rem;
}

.modal-header {
  border: 0;
  button {
    &:before {
      display: none;
    }
  }
}

.modal-popup .modal-header,
.modal-popup .modal-footer {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.modal-popup .modal-header {
  padding-bottom: 1.2rem;
  padding-top: 3rem;
}

.modal-popup .modal-footer {
  margin-top: auto;
  padding-bottom: 3rem;
  padding-top: 3rem;
  border: none !important;
}

.modal-popup .modal-footer-actions {
  text-align: right;
}

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
  background: none;
  border: none;
}

.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
  background: none;
  border: none;
}

.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 14px;
  color: #8f8f8f;
  content: '\e616';
  font-family: 'luma-icons';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
  color: inherit;
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
  color: inherit;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
  color: #1a1a1a;
}

.modal-custom .action-close {
  margin: 25px;
}

.modal-content {
  border: 0;
  text-align: center;
  font-weight: 400;
}

.modal-popup .modal-title {
  padding-bottom: 10px;
  font-size: 26px;
  margin-bottom: 0;
  min-height: 1em;
  word-wrap: break-word;
}


.modal-popup .action-close {
  padding: 20px !important;
  margin-right: 20px;
  font-size: 24px;
  cursor: pointer;

  &:before {
    display: none;
  }
}

.modal-slide .action-close {
  padding: 2.1rem 3rem;
}

.modal-slide .page-main-actions {
  margin-bottom: -12.9rem;
  margin-top: 2.1rem;
}

.modals-overlay {
  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1499 !important;
}

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1050;
}

.modal-inner-wrap {
  background-color: $white;
}

.subscription-modal, .modal-popup.confirm {
  text-align: center;

  .action-close {
    outline: none;
    margin-right: 10px;
    &:focus {
      outline: none;
    }
    .icons-cross {
      font-size: 28px;
    }
  }

  .modal-inner-wrap {
    max-width: 700px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
  }

  .modal-header {
    text-align: center;
    padding-bottom: 0;
    padding-top: 2rem;
    border: none;
    min-height: 70px;

    .action-close {
      font-size: 24px;

      &:before {
        display: none;
      }
    }
  }

  .modal-title {
    width: 100%;
    border: none;
    padding-top: 30px;
  }

  .modal-footer {
    border: none;
    padding-bottom: 20px;
    padding-top: 40px;
    display: flex;
    z-index: 100;
    button {
      margin: auto;
    }
  }
}

.category-gift-options, .catalog-product-view {
  .subscription-modal {
    .modal-footer {
      display: initial;
      button {
        margin: 0px 5px;
      }
    }
  }
}


.modal-popup {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.checkout-cart-index {
  .popup-authentication {
    .modal-inner-wrap {
      margin: auto;
    }
  }
}


.catalog-product-view {
  .subscription-modal {
    .modal-header {
      padding-top: 20px;
    }
    .modal-footer {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }
}


.click-collect-popup{

}
