.menu{
  list-style: none;
  margin: 10px 0 0 0;
  padding: 10px 0px;
  li{
    display: inline-block;
    margin: 0;

    &.active{
      .submenu--wrap{
        opacity: 1;
        visibility: visible;
          z-index: $zindex--submenu--wrap;
      }

      &>a{
        &::after{
          opacity: 1;
          visibility: visible;
        }
      }
    }

    >a::after{
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -16px;
      content: '';
      background: url('/media/theme/triangle-top.svg') 0 0 / contain no-repeat;
      width: 32px;
      height: 32px;
      margin-bottom: -38px;
      opacity: 0;
      visibility: hidden;
      @include generate-transition(all, 300ms, ease-in-out);
    }

    a{
      color: $grey--darkest;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      font-size: 12px;
    }
  }
}

.submenu--wrap{
  position: absolute;
  background: $grey--darkest;
  top: 0;
  left: 0;
  padding: 40px;
  z-index: -1;
  margin-top: 59px;
  text-align: left;
  width: 100%;
  opacity: 0;
  visibility: visible;
  @include generate-transition(all, 300ms, ease-in-out);

  .submenu{
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-right: -5px;
    li{
      display: inline-block;
      text-align: left;
      font-size: 18px;
      vertical-align: top;
      padding-bottom: 10px;
      width: 30.333333%;
      margin-left:0;
      li{
        display: block;
        width: initial;
      }

      a.title{
        color: $orange--normal;
        padding-bottom: 5px;
        margin-bottom: 15px;
        border-bottom: 1px solid $white;
        text-align: left;
        font-weight: 700;
        display: block;
        text-transform: uppercase;
        font-size: 18px;
      }

      a{
        color: $white;
        font-size: 14px;
        padding-right: 30px;
        text-transform: none;
        font-weight: 400;
        &:hover{
          color: $orange--normal;
        }
      }
    }

    .submenu{
      display: block;
      li{
        margin-left: 0;
      }
    }
  }

  .submenu--wrap__close{
    color: $white;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 16px;
    right: 24px;
    @include generate-transition(all, 250ms, ease-in-out);
    &:hover{
      color: $orange--normal;
    }
  }
}
