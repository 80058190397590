header {
    @include generate-transition(all, 300ms, ease-in-out);
    z-index: 9;
    .livechat,
    .trust-point {
        animation: fadeOut 1s forwards;
        @keyframes fadeOut {
            0% {
                opacity: 0;
                display: none;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .header {
        padding: 30px 0;
        @include media-breakpoint-down(lg) {
            padding: 25px 0;
            .full-logo {
                width: 120px;
            }
            .container {
                padding: 0 24px;
            }
            #close-list-btn-two {
                img {
                    display: none
                }
            }
        }
        @include generate-transition(all, 300ms, ease-in-out);
        .logo {
            @include media-breakpoint-up(xl) {
                width: 180px;
            }
        }
        .half-logo {
            display: none;
        }
        .serach-cart {

            .trigger--close__autocomplete{
                position: absolute;
                top: 0;
                right: 0;
                z-index: 10;
                background-color: $black;
                width: 47px;
                height: 43px;
                border-radius: 5px;
                margin: 6px 5px 0 0;
                line-height: 39px;
                text-align: center;
                font-size: 28px;
                display: none;
                cursor: pointer;
            }

            @include media-breakpoint-down(lg) {
                text-align: end;
            }
            input {
                height: 55px;
                line-height: 55px;
                background-color: $white;
                width: 100%;
                padding-right: 40px;
                &::placeholder {
                    color: $black;
                }

                @include media-breakpoint-down(lg) {
                    width: 100%;
                    min-width: auto;
                    max-width: calc(100%);
                }

            }
            .search-btn {
                background: $black;
                width: 45px;
                height: 45px;
                right: 2px;
                top: 5px;
                border-radius: 5px;
                @include media-breakpoint-down(lg) {
                    width: 47px;
                    height: 43px;
                    border-radius: $borderRadiusX;
                    margin-top: 2px;
                    line-height: 43px;
                }
            }
            .cart-btn {
                .badge {
                    position: absolute;
                    font-size: 9px;
                    width: 21px;
                    height: 21px;
                    min-width: 21px;
                    line-height: 21px;
                    text-align: center;
                    padding: 0;
                    margin: -10px -9px 0 0;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .menu-links {
                position: absolute;
                z-index: 99;
                top: 227px;
                left: 0;
                height: calc(100vh - 180px);
                padding-bottom: 120px;
                overflow-y: auto !important;
                ul {
                    max-width: 357px;
                    width: 100%;
                    background-color: #33363a;
                    li {
                        width: 100%;
                        min-width: 357px;
                        padding: 17px 0;
                        border-bottom: 1px solid $extraLightBlack;
                        a {
                            text-transform: capitalize !important;
                            svg {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }
    }
    .trust-point {
        background-color: #4E4E4E;
        padding: 10px 0;
        @include media-breakpoint-down(lg) {
            padding: 8.5px 0;
            margin-top: -1px;
        }
    }
}
.header-fixed {
    @include generate-transition(all, 300ms, ease);
    @include media-breakpoint-down(lg) {

        .open-menu-icon{
            display: inline-block;
        }
        

        .menu-links {
            top: 100px !important;
            height: calc(100vh - 100px);
        }
        .search-toggle {
            margin-top: 0 !important;
            #toggle-btn {
                display: none !important;
            }
            // .serach-cart {
            //     display: none !important;
            // }
            #close-menu {
                display: none !important;
            }
            #close-list-btn {
                display: none !important;
            }
        }
        .calling-feature {
            display: none !important;
        }
        .logo {
            justify-content: space-between;
        }
        .fixed-toggle {
            display: block !important;
            margin-right: 40px !important;
        }
        .fixed-menu {
            display: flex !important;
        }
        .fixed-search {
            display: inline-block !important;
            background-color: rgba(255, 255, 255, 0.10);
        }
    }
    .livechat,
    .trust-point {
        display: none;
    }




    ul.header--menu{
        top: 114px !important;
    }

    .header {
        padding: 10px 0;
        background-color: $extraLightBlack !important;
        @include media-breakpoint-up(xl) {
            .full-logo {
                display: none;
            }
            .half-logo {
                display: block;
            }
        }
        @include media-breakpoint-down(lg) {
            padding: 40px 0px;
        }
    }
    &.hide-top {
        @include media-breakpoint-down(lg) {
            .logo {
                display: none;
            }
            .serach-cart {
                display: block !important;
            }
            #close-list-btn {
                display: block !important;
            }
            .search-list-scroll {
                transform: translate(-50%, 0) !important;
                top:95px !important;
                height:calc(100vh - 95px) !important;
            }
        }
    }
}


#product-modal{
    svg path{
        fill: $golden;
    }
}

#search_autocomplete{
    position: relative !important;
    color: $black;
    width: 100% !important;

    @include media-breakpoint-down(lg) {
        overflow-y: scroll;
    }

    a{
        color: $black;
    }

    .price{
        font-size: $fontXS;
        height: 13px;
    }

    .mt-lg-3{
        margin-top: 0 !important;
    }

    .latest--product__slider{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;
        .product{
            flex: 0 0 auto;
            width: 25%;
            margin-bottom: 20px;
            padding: 0 7.5px;
            @include media-breakpoint-down(lg) {
                width: 50%;
            }
        }
    }
}

.main-search {
    background: none !important;
    background-color: none !important;
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    &::placeholder{
        color: $black;
    }
}

.header--menu{
    margin-right: 40px;
    width: 80%;
    @include media-breakpoint-down(lg) {
        position: fixed !important;
        top: 200px !important;
    }

    .sub-menu-trustpilot{
        width: 200px;
    }

    @include media-breakpoint-down(lg) {
        .sub-menu-contact{
            font-size: 20px !important;
            padding-bottom: 1px;
        }
    }
    
    &>.parent{
        a{
            position: relative;
            &::after{
                content: '';
                background: url('/static/frontend/TheCurious/BCMW/en_GB/images/arrow-menu.svg') center/contain no-repeat;
                width: 10px;
                height: 10px;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 50%;
                margin-top: -5px;
                margin-left: 10px;
            }
            margin-right: 10px;
        }

        @include media-breakpoint-up(lg) {
            &:hover{
                .sub-menu{
                    display: block;
                }
            }
        }

       
    }

    @include media-breakpoint-down(lg) {
        .sub-sub-menu {
            display: none;
            position: fixed;
            padding: 0px 0px;
            top: 200px;
            width: 100%;
            left: 0;
            background-color: $white;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            z-index: 200;
            height: calc(100vh - 180px);
            padding-bottom: 120px;
            overflow-y: scroll;
            overflow-x: hidden;
            &.active{
                display: block;
            }
        }
    }

    .sub-menu {
        display: none;
        position: absolute;
        padding: 0px 0px;
        top: 44px;
        width: 100%;
        left: 0;
        background-color: $white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 200;
        max-height: calc(100vh - 130px);
        overflow-y: scroll;
        overflow-x: hidden;
        font-weight: 400 !important;

        &.sub-sub-menu-active{
            
        }

        .sub-menu-block{
            padding: 20px 0px;


            ul{
                padding: 0;
                a{
                    margin-right: 0;
                    border-bottom: 1px solid #E6E6E6 !important;
                    padding: 15px !important;
                }
            }

            
            .text-end{
                padding: 0;
                text-decoration: underline;
                border-bottom: 0;
                font-size: 14px !important;
            }

            a{
                font-size: 20px !important;
                margin-right: 0;
                text-decoration: none;
                text-align: left;
                display: block;
                padding: 10px 15px;
                
                &::before, &::after{
                    display: none;
                }
            }

            p{
                img{
                    width: calc(100% - 30px);
                    @include media-breakpoint-down(lg) {
                        margin-left: 15px;
                    }

                    border-radius: 5px;
                }
            }
            
        }


        &.active{
            display: block;
            @include media-breakpoint-down(lg) {
                .text-left.row.mx-3.no-gutter.mx-lg-0.align-items-end{
                    display: flex !important;
                }
            }
        }

        .col-lg-9 >.level1{
            padding: 10px 15px;
            @include media-breakpoint-down(lg) {
                padding: 10px 16px;
            }
            
        }
    
        @include media-breakpoint-down(lg) {
          width: 100%;
          position: fixed;
          top: 200px;
          left: 0;
          height: calc(100vh - 190px);
          padding-bottom: 120px;
    
          .children{
            width: 100%;
            margin-top: 0;
          }
    
          li{
            a{
              font-size: 18px;
            }
          }
        }
    
        li {
            display: block;
            text-align: left;
            margin: 0 0px;
            padding: 0px 0px;
            @include media-breakpoint-up(lg) {
                line-height: 12px;
            }
            @include media-breakpoint-down(lg) {
                position: initial;
            }
    
            &:last-child() {
                border-bottom: unset;
            }

            &.level2.parent{
                //position: relative;
                & > a{
                    font-size: 18px;
                    display: block;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        margin-right: 0;
                    }    


                    &::before{
                        content: '';
                        background: url('/static/frontend/TheCurious/BCMW/en_GB/images/arrow-menu-black.svg') center/contain no-repeat;
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 50%;
                        right: 18px;
                        transform: rotate(-90deg);
                        margin-top: -5px;
                        @include media-breakpoint-down(lg) {
                           right: 18px;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    a{
                        font-size: 18px;
                        display: block;
                        position: relative;
                        @include media-breakpoint-down(lg) {
                            margin-right: 0;
                        }    

                        &::before{
                            content: '';
                            background: url('/static/frontend/TheCurious/BCMW/en_GB/images/arrow-menu-black.svg') center/contain no-repeat;
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            top: 50%;
                            margin-top: -5px;
                            right: 18px;
                            transform: rotate(-90deg);
                            @include media-breakpoint-down(lg) {
                             right: 18px;
                            }
                        }
                    }
                }


            } 
    
            a {
                color: $black !important;
                padding: 10px 0px;
                border-bottom: 1px solid $grey--light;
                display: block;
                margin-bottom: 5px;
                text-transform: initial;
               
    
                &:after {
                    content: unset !important;
                }
            }
        }
    
        &.active {
            display: block;
        }

        .level2{
            .row{
                display: flex;
                margin-bottom: 10px;
                .col-lg-3{
                    flex: initial;
                    width: 100%;
                    a{
                        @include media-breakpoint-up(lg) {
                            border-bottom: 0;
                            padding-bottom: 0px;
                            margin-bottom: 0px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}


header{
    z-index: 100;
}

.trust-pilot-logo-header{
    width: 200px;
    height: auto;
    max-width: 100%;
  }



 .triggerViewMore{
    font-size: 12px;
    color: $white;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin: -5px 0px 10px;
    margin-left: 1px;
 }

 .page-header{

    @include media-breakpoint-up(lg) {

        &.header-scrolled{
            .livechat, .trust-point, .full-logo{
                display: none !important;
            }

            .half-logo{
                display: block !important;
            }

            .header{
                padding: 15px 0px;
            }
        }

    }

    @include media-breakpoint-down(lg) {

        .serach-cart{
            width: 100%;
            display: inline-block !important;
        }

        &.header-scrolled{
             .calling-feature, .livechat, .trust-point{
                display: none !important;
            }


            // .cart-btn{
            //     position: absolute !important;
            //     top: 50%;
            //     right: 22px;
            //     margin-top: -35px;
            // }


            ul.header--menu{
                top: 0px;
                &.active{
                    height: calc(100vh - 160px);
                }
            }

            .header--menu, .sub-menu, .sub-sub-menu{
                height: calc(100vh - 160px);
            }
        }
    }
 }


 #klevuSearchingArea{
    display: flex;
 }

 body{

    .klevuResultsBlock .klevuProductsViewAll a{
        border-bottom: 1px solid $black;
        padding: 0px;
    }

    .klevuAutoSuggestionsWrap{
        border-bottom: none;
    }

    .klevuHeadingText{
        color: $silentGray;
    }

    .klevuResultsBlock .klevuQuickSearchResults ul li{
        border-bottom: none;
    }

    @include media-breakpoint-up(lg) {

        .klevuResultsBlock .klevuQuickSearchResults ul{
            border-right: 1px solid $lightGray;
        }

        .kuEmptySearchBox{
            [ku-container][data-container-role="main"]{
                display: block;
            }

            [ku-container][data-container-role="header"],
            [ku-container][data-container-role="content"]
            {
                width: auto;
            }
        }

        .klevuTarget.klevu-fluid>div{
            width: 50%;
            max-width: 850px;
            position: fixed;
            border-radius: 12px;
            padding: 20px;

            .klevuQuickSearchingArea{
                box-shadow: none;
            }
        }

        &.body-header-scrolled{
            .klevuTarget.klevu-fluid>div{
                top: 77px !important;
            }
        }
        
        [ku-container][data-container-role="main"]{
            display: flex;
        }
        
        [ku-container][data-container-role="header"]{
            flex: 0 0 auto;
            width: 50%;
            order: 2;
    
        }
        
        [ku-container][data-container-role="content"]{
            flex: 0 0 auto;
            width: 50%;
            order: 1;
        }

    }

    @include media-breakpoint-down(lg) {
        .klevuTarget.klevu-fluid>div{
            position: fixed;
        }

        &.body-header-scrolled{
            .klevuTarget.klevu-fluid>div{
                top: 77px !important;
                left: 73px !important;
            }
        }
        
    }
 }


 .checkout-onepage-success{
    .page-header{
        display: none !important;
    }
 }

 
 .trustpilot-logo{
    width: 180px;
    max-width: 100%;
 }

 .menu-bg{
    background: #2D2D2D;
 }

 .header-blocks{
    padding: 20px;
    color: $black;
    p{
        color: $black;
        text-transform: initial;
    }
    a{
        color: $black !important;
        text-decoration: underline;
        text-transform: initial;
    }
 }

 .header-menu-title, .header-menu-view-all{
    color: $black;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 24px;
    line-height: 20px;
    @include media-breakpoint-down(lg) {
        padding-left: 0;
    }
    a{
        color: $black !important;
    }
 }

 .header-menu-view-all{
    padding-top: 0px;
    a{
        text-transform: uppercase !important;
        text-decoration: underline;
        font-weight: 300;
        font-size: 12px !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        border-bottom: 0 !important;
        @include media-breakpoint-down(lg) {
            font-size: 14px !important;
            padding-top: 0 !important;
            padding-right: 0 !important;
            margin-right: 0 !important;
            img{
                display: none !important;
            }
            &::before{
                display: none !important;
            }
        }
    }
 }

 .action{
    .counter{
        width: 20px;
        height: 20px;
        text-align: center;
        letter-spacing: 0px;
        padding: 0;
        line-height: 20px;
        margin: -8px -8px 0 0;
    }
 }

 .menu-category-image{
    margin-right: 10px;
    border-radius: 5px;
    width: 60px;
    height: auto;
 }

 body{
   &.body-header-scrolled{
        .header--menu, .sub-menu, .sub-sub-menu{
            top: 46px !important;
            @include media-breakpoint-down(lg) {
                top: 166px !important;
            }
        }
    }
 }

.sub-sub-menu-back{
    font-weight: 400 !important;
}
