
.product-open--top {
    position: relative;
    // background-color: #f8f2e1;
    overflow: hidden;
    padding: 0px;

    > .slick-dots {
        display: flex;
        width: 100%;
        bottom: 30px;
        padding: 0;
        position: absolute;
    }

    .return-to-products--link {
        margin-top: 18px;
        margin-bottom: 0px;
        font-size: 12px;

        a {
            @include generate-transition(all, 500ms, ease);

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .lifestyle-image--container {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        @include generate-transition(all, 500ms, ease);
    }

    #slider-view, #slider-thumbnails {
        opacity: 0;

        &.slick-initialized {
            opacity: 1;
        }

        @include generate-transition(all, 500ms, ease);
    }

    .hidden {
        opacity: 0 !important;
        @include generate-transition(all, 500ms, ease);
    }

    .active {
        opacity: 1;
        @include generate-transition(all, 500ms, ease);
    }


    .lifestyle-image {
        &--container {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    img {
        margin: 0 auto;
        cursor: pointer;
        width: 100%;
        height: auto;

        &:hover {
            opacity: 0.5;
        }
    }

    .imageSlider--item{
      height: 400px;
    }
}

.swash--button {
    &.tocart {
        padding: 30px 45px !important;
        margin-left: 20px;
    }
}

.product--top__bar {
    padding: 0px 0px;
    background-color: $white !important;
    z-index: 10;

    &.is_stuck {
        margin-top: -44px;
    }

    .product--name {
        font-size: 20px;
    }


    .product--name {
        text-transform: capitalize;
        margin-top: 8px;
    }

    .product--titles {
        opacity: 0.5;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: -5px;
    }

}

.product-open--info__section {
    .font--small {
        color: rgba($black, 0.5);
    }

    margin-bottom: 60px;
}


.slick-dots {
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 1rem 0;

    list-style-type: none;

    li {
        margin: 0 0.25rem;
    }

    button {
        display: block;
        width: 12px;
        height: 12px;
        padding: 0;
        cursor: pointer;

        border-radius: 100%;
        background-color: transparent;
        text-indent: -9999px;
        border: 1px solid $white;
    }

    li.slick-active button {
        background-color: $white;
        border: 1px solid $white;
    }
}

#slider-view {
    .slick-dots {
        margin-top: 15px;
    }
}

.quantity--wrap {
    margin-right: 10px;

    span {
        display: inline-block;
        font-size: 16px;
        margin: 0px 2px;
        cursor: pointer;
        @include generate-transition(all, 500ms, ease);

        &:hover {
            opacity: 0.5;
        }
    }

    .qty {
        margin: 0 5px;
        padding: 0;
    }

    input {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 16px;
        text-align: center;
        border: 1px solid rgba($black, 0.2);
        padding-right: 2px;
        border-radius: 4px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.product-open--bottom__icon--bar {
    border-bottom: 1px solid rgba($black, 0.2);

    i {
        font-size: 125px;
    }
}


.quantity--and__add-to-basket--product__row {
    padding-top: 10px;

    hr {
        margin: 5px 0px;
    }
}

.block.related {
    width: 100%;
}


.product--title, .catalog-product-view .product--price {
    font-size: 28px;
    text-transform: uppercase;
    font-family: $sans-serif;
}

.product--gallery__slider {
    margin: 20px 30px;
    position: relative;

    .slide {
        text-align: center;

        img {
            padding: 10px;
            display: inline-block;
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        background: none;
        width: 24px;
        height: 24px;
        z-index: $zindex-product--gallery__slider;
        border: 0;
        margin-top: -12px;
        color: #ddd;
        font-size: 24px;

        &.slick-prev {
            left: 0;
            margin-left: -24px;
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
        }

        &.slick-next {
            right: 0;
            margin-right: -24px;
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
        }
    }
}


.product--video {
    position: relative;
    width: 100%;
    height: 500px;

    .fa-play {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        font-size: 36px;
        line-height: 58px;
        margin-top: -18px;
        margin-left: -18px;
        z-index: 50;
        cursor: pointer;
        border: 3px solid $white;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        text-align: center;
        padding-left: 5px;

        &:hover {
            color: $orange--normal;
            border-color: $orange--normal;
        }
    }

    .product--video__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }
}

.product--qty {
    display: inline-block;
    padding-left: 10px;
    position: relative;

    input[type="text"] {
        border: 2px solid $grey--dark;
        padding: 0 10px;
        font-size: 21px;
        line-height: 48;
        font-weight: 700;
        width: 53px;
        height: 53px;
        vertical-align: middle;
        text-align: center;
    }

    .fa {
        border: 2px solid $grey--dark;
        padding: 5px;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -25px;
        cursor: pointer;
        z-index: $zindex--product--qty;

        &:hover {
            color: $orange--normal;
        }
    }

    .fa-minus {
        margin-top: 25px;
    }

}

.product--stock__status {
    text-transform: uppercase;
    color: $green--soft;
    padding: 8px 25px;
    font-size: 14px;
    border: 1px solid $grey--dark;
    border-radius: 12px;
    display: inline-block;
}


.star-rating {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 125px;
    height: 25px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating input {
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
}

.star-rating input:hover + i,
.star-rating input:checked + i {
    opacity: 1;
}

.star-rating i ~ i {
    width: 40%;
}

.star-rating i ~ i ~ i {
    width: 60%;
}

.star-rating i ~ i ~ i ~ i {
    width: 80%;
}

.star-rating i ~ i ~ i ~ i ~ i {
    width: 100%;
}



#slider-thumbnails {
    .slider-thumbnail-image {
        margin: 0 auto;
        height: 90px !important;
    }
}


body .product-view .product-shop{
  width: 100%;
}

.gallery--row{
  border: 1px solid #c3b28e;
  margin-bottom: 20px;
  margin-left: 0px;
  padding: 10px 20px;
}

.button-basket{
  min-width: 200px;
}

#amfaq-ask-form-inline{
  textarea{
    width: 100%;
    max-width: 100%;
  }
}

.amfaq-form-submit{
  margin-top: 29px;
}

.amfaq-form-submit, .default--buttonOld{
    width: 80%;
    height: 30px;
    background: #fff url(../images/button-bg.jpg) no-repeat top left;
    font-size: 13px;
    text-transform: none;
    display: inline-block;
    padding: 7px 15px;
    border: 0;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: bold;
    font-family: Arial, "Helvetica", sans-serif;
    line-height: 19px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}


.onestepcheckout-index-index .mto {
    clear: left;
    color: green;
    text-align: center;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px 0 0px !important;
    display: block;
    float: none !important;
    padding-top: 20px;
}

.add-to-box{
  input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text]{
    width: 100%;
  }
}

body .product-shop .mto{
  margin: 0 !important;
  padding: 0 !important;
}


.catalog-product-view{
  span.weee{
    display: inline;
  }

  .old-price{
    .custom-label{
      color: grey;
      font-weight: 700;
    }
  }

  .special-price{
    .custom-label{
      color: #c76200;
      font-weight: 700;
    }
  }
}

.product--specs li.active a{
  color: $gold;
}



.tab-content-mobile-overview{
     padding: 0;
     border: none;
    .tab-content-overview, .tab-content-dimensions{
        padding: 15px;
        border: 1px solid #c3b28e;
        margin-bottom: 15px;
        overflow: hidden;
    }
    .tab-content-overview{
        .std{
            p{
                text-align: left;
            }
            ul{
                text-align: left;
                margin-left: 30px;
                li{
                    display: list-item;
                    border: none;
                    padding: 0;
                }
            }
        }
    }
}

.products-related, .block.upsell{
  .price-box .price-label, .custom-label{
    display: none;
  }



}

.click-collect-popup{
  #clickCollectInfo{
    height: auto;
  }

  .actions-toolbar .primary{
    float: none;
  }

  .modal-inner-wrap{
    top: 0;
  }

  .modal-content{
    padding-top: 10px;
  }

  .modal-footer, .modal-header{
    display: none !important;
  }

  .modal-popup .modal-inner-wrap{
    top: 0;
  }

  #modal-content-1{
    display: block;
    background: none;
    padding: 0;
    border-radius: 0;
  }

  label{
    display: none;
  }

  .icon-cross{
    position: absolute;
    top: 10px;
    right: -30px;
    z-index: 20;
    cursor: pointer;
  }
}


.ask-question-form.widget{
  .form-toggle-button.action{
    display: none !important;
  }
}

.amfaq-product-tab{
  .am-title{
    cursor: pointer;
  }

  .am-links{
    display: none !important;
  }
}


#qty-error-message{
  font-size: 12px;
}

.catalog-product-view{
  .products-grid .actions .button-basket{
    min-width: initial;
  }
}


body{
    .accordion--row[aria-expanded="true"] {
        .accordion-button{
            &::after{
                transform: rotate(45deg) !important;
            }
        }
    }
}


#delivery-modal{
    *{
        color: $black;
    }
}

.popup--container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    visibility: hidden;
    opacity: 0;

    &.active{
        visibility: visible;
        opacity: 1;
    }

    .popup--block{
        position: relative;
        height: 100vh;
        width: 100vw;
        .popup--outer{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .popup--contents{
                color: $white;
                background-color: $black;
                width: 630px;
                height: 280px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                .headingM{
                    font-size: 30px;
                }
                @media (max-width: $md) {
                    width: 100vw;
                    height: 100vh;
                    border-radius: 0;
                }
                .input--box__wrap{
                    width: 80%;
                    border-radius: 10px;
                    // background-color: $offwhite;
                    height: 40px;
                    align-items: center;
                    position: relative; 
                    margin: 0 auto;

                    input[type="email"]{
                        padding: 0 10px;
                        border: none;
                        background: none;
                        width: 100%;
                        line-height: 40px;
                        background: $white;
                        color: $black;
                        &::placeholder{
                            font-size: $fontS;
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                    .default--button__gold{
                        color: $white!important;
                        height: 40px;
                        border-radius: 0px 10px 10px 0px;
                        line-height: unset;
                        padding: 0;
                        width: 50px;
                        padding-top: 3px;
                        border: 0;
                        position: absolute;
                        top: 0;
                        right: -12px;
                        line-height: 10px;
                    }
                }
            }
        }
    }
    .default--icon{
        height: 50px;
        width: 50px;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        background: $white;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
        margin: 25px;
        color: $black;
        svg{
            rotate: 90deg;
            path{
                fill: $black;
            }
        }
        @media (max-width: $md) {
            top: 30px;
            right: 30px;
            height: 50px;
            width: 50px;
            padding: 15px;
        }
    }
}