


.block--delivery{
  color: $white;
}

.section--delivery__bikes{
  width: 100%;
  position: relative;
  height: 700px;
  overflow: hidden;
}

.delivery--bike__left{
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -600px;
  top: 50%;
  margin-top: -257.5px;
}

.delivery--bike__right{
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -600px;
  top: 50%;
  margin-top: -257.5px;
}


.section--delivery{
  width: 100%;
  position: relative;
  height: 400px;
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;

  .title--delivery{
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: $sans-serif;
  }
  p{
    font-size: 21px;
    font-weight: 500;
  }
}

.delivery--text{
  .section--subtitle{
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  p{
    font-size: 16px;
  }
}

.large-number{
  color: $white;
  font-size: 72px;
  display: inline-block;
  border: 10px solid $white;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  line-height: 80px;
  margin: 40px 0px;
}

.delivery--list{
  color: $grey--darkest;
  text-transform: uppercase;
  font-size: 28px;
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.delivery--title{
  font-size: 36px;
  text-transform: uppercase;
  padding-bottom: 30px;
  font-weight: 700;
  font-family: $sans-serif;
  a{
    color: $orange--normal;
    &:hover{
      color: $grey--dark;
    }
  }
}

.section--subtitle__delivery{
  font-family: $sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  padding-bottom: 30px;
  font-weight: 700;
  margin-top: 30px;
}

.delivery--step{
  padding: 40px 0px;
  .delivery--step__image{
    width: 30%;
    float: left;
  }

  .delvery--step__text{
    width: 70%;
    float: left;
    padding-left: 30px;

    .delvery--step__title{
      color: $red--dark;
      font-size: 36px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: $sans-serif;
      margin-bottom: 10px;
    }
  }
}

.vertically-middle-custom{
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -45.5px;
  z-index: 50;
}
