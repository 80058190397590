@import 'variables';


body,
html {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
  color: $white;
}
body{
  background-color: $extraLightTwoBlack;
}

a{
  color: $white;
  transition: all 400ms ease;
  @include media-breakpoint-up(lg) {
    &:hover{
      opacity: 0.8;
    }
  }
}

button{
  transition: all 400ms ease;
  @include media-breakpoint-up(lg) {
    &:hover{
      opacity: 0.8;
    } 
  }
}

a, a:hover, a:visited {
  color: inherit;
  text-decoration: none;
}


p{
  margin-bottom: 0;
  color: $white;
}

ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

*{
  outline: none;
}


.no-gutter > [class*='col-'] {
  padding-right:0;
  padding-left:0;
}

img{
  max-width: 100%;
  height: auto;
}

.align--center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.height--75{
  height: 75vh;
}

.height--80{
  height: 80vh;
}

.height--100{
  height: 100vh;
}

.padding-top--10px {
  padding-top: 10px;
}

.padding-top--20px {
  padding-top: 20px;
}

.padding-top--40px {
  padding-top: 40px;
}

.padding-top--60px {
  padding-top: 60px;
}

.padding-top--80px {
  padding-top: 80px;
}

.padding-top--100px {
  padding-top: 100px;
}

.font--small {
  font-size: 12px;
  line-height: 1.2;
}

.heading--large {
  font-weight: $bold;

  font-size: 55px;
  line-height: 1.2;
}

.heading--medium {
  font-weight: $bold;

  font-size: 40px;
  line-height: 1.2;
}

.default--link {
  display: inline-block;
  border-bottom: 1px solid $white;
  font-weight: $medium;

  font-size: 14px;
  cursor: pointer;
  @include generate-transition (all, 500ms, ease);

  &:hover {
    opacity: 0.5;
  }
}


.hidden {
  display: none;
}

.hide--on__desktop {
  display: none;
}


.link--underline{
  border-bottom: 1px solid $black;
  &.white{
    border-color: $white;
  }
}


img{
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.padding-top--20px{
  padding-top: 20px;
}

.padding-top--40px{
  padding-top: 40px;
}

.padding-top--60px{
  padding-top: 60px;
}

.padding-top--300px{
  padding-top: 300px;
}


.default--button{
  font-size: 14px;
  padding: 20px 35px;
  border-radius: 5px;
  border: 1px solid transparent;
  color: $white;
  display: inline-block;
  line-height: 100%;
  text-transform: capitalize;
  max-width: 100%;
  cursor: pointer;

  &__gold{
    font-size: 14px;
    padding: 20px 35px;
    border-radius: 5px;
    border: 1px solid transparent;
    color: $white;
    display: inline-block;
    line-height: 100%;
    text-transform: capitalize;
    max-width: 100%;
    cursor: pointer;
    @extend .bg-secondary;
  }


  &:hover{
    background: $white;
    color: $gold;
    border: 1px solid $blue;
  }

  &.default--button__bg--white{
    background: $white;
    color: $grey--darkest;
    &:hover{
      background: $grey--darkest;
      border-color: $grey--darkest;
      color: $white;
    }
  }

  &.default--button__bg--orange{
    background: $orange--normal;
    color: $white;
    border-color: $orange--normal;
    &:hover{
      background: $grey--darkest;
      border-color: $grey--darkest;
      color: $white;
    }
  }

  &.default--button__border--black{
    color: $grey--darkest;
    border-color: $grey--darkest;
    &:hover{
      background: $grey--darkest;
      color: $white;
    }
  }
}

.bg--red__dark{
  background: $red--dark;
}

.bg--grey__darkest{
  background: $grey--darkest;
}

.bg--grey__light{
  background: $grey--light;
}


.bg--orange__normal{
  background: $orange--normal;
}

.bg-red{
  background: #CC2325;
}

.bg--black{
  background: $black;
}

.block--img500{
  position: relative;
  width: 100%;
  height: 500px;

  &.block--text{
    padding: 50px;

    .vertically-middle{
      width: 80%;
      left: 10%;
    }
  }
}

.block--img300{
  position: relative;
  width: 100%;
  height: 300px;
}


.overlay--orange{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,165,6, 0.5);
  z-index: $zindex--overlays;
}

.overlay--red{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(127,22,47, 0.5);
  z-index: $zindex--overlays;
}

.overlay--black{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0, 0.5);
  z-index: $zindex--overlays;
}

.social-media{
  list-style: none;
  margin: 0;
  padding: 0;
  li{
    display: inline-block;
    margin: 0 10px;
    a{
      color: $orange--normal;
      display: block;
      font-size: 24px;

      &:hover{
        color: $black;
      }
    }
  }
}

.messages .success-msg li{
  color: $white;
  border-left: 0;
  background-color: $orange--normal;
}

.hr--special{
  margin-top: -10px;
  margin-bottom: 40px;
  border-top-color: $grey--light;
}



.bb-icons{
  display: inline-block;
  font: normal normal normal 14px/1 $font--icons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon--basket:before {
    content: "\e900";
  }
  &.icon--left:before {
    content: "\e901";
  }
  &.icon--search:before {
    content: "\e902";
  }
  &.icon--close:before {
    content: "\e903";
  }
}

.color--orange{
  color: $orange--normal;
}

.color--grey{
  color: $grey--dark;
}

.color-white{
  color: $white !important;
}

.color-black{
  color: $black !important;
}


.vertically-middle{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 50;
  @include generate-transition(all, 500ms, ease-in-out);
  &.vertically-middle--51{
    z-index: 51;
  }
}


.select2-container{
  width: auto !important;
}

.action.showcart{
  position: relative;
}


h1, h2, h3{
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
}

.color--white{
  color: $white !important;
}

.catalogsearch-result-index{
  .breadcrumbs{
    margin: 0;
    padding: 0px 13px 13px 0px;
  }
}

.category-view{
  .breadcrumbs{
    margin: 0;
    padding: 13px;
  }
}

.notice-text{
  a{
    color: $white;
  }
}

.catalog-product-view{


  .breadcrumbs{
    padding-top: 0;
    margin-top: 0;
  }
}

.header-menu .welcome-msg{
  margin-bottom: 0;
  padding-bottom: 0;
}

.webforms{
  .field.type-text {
    width: 50%;
    float: left;
  }
  .field{
    width: 100%;
    float: left;
    padding-bottom: 20px;
  }

  .choice{
    width: 30%;
    float: left;
    font-style: italic;
  }
}


.addthis_sharing_toolbox.blog__post-meta-sharing{
  margin-top: 15px;
  display: block;
}

.selectize{
  display: none;
}

.font-bold{
  font-weight: 700;
}

.font-gold{
  color: $gold;
}

body .modal-popup._inner-scroll .modal-inner-wrap{
  width: 500px;
  .modal-footer{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.copyright{
  display: none !important;
}


.loading-mask, #loading-mask{
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
}


// Theme Content CSS 
.theme-content {}
// BACKGROUND COLOR
.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary;
}
.bg-lightBlack {
    background-color: $lightBlack;
}
.bg-extraDarkGray {
    background-color: $extraDarkGray;
}
.bg-darkGray {
    background-color: $darkGray;
}
.bg-white {
    background-color: $white;
}
.bg-gray {
    background-color: $gray;
}
.bg-golden {
    background-color: $golden !important;
}
.bg-extraLightBlack {
    background-color: $extraLightBlack;
}
.bg-lightGray {
    background-color: $lightGray;
}
.bg-silentBlack {
    background-color: $silentBlack;
}
.bg-extraLightTwoBlack {
    background-color: $extraLightTwoBlack;
}

// TEXT COLOR
.text-primary {
    color: $primary;
}
.text-secondary {
    color: $secondary;
}
.text-lightBlack {
    color: $lightBlack;
}
.text-darkGray {
    color: $darkGray;
}
.text-extraDarkGray {
    color: $extraDarkGray;
}
.text-white {
    color: $white;
}
.text-gray {
    color: $gray;
}
.text-golden {
    color: $golden;
}
.text-extraLightBlack {
    color: $extraLightBlack;
}
.text-light {
    color: #fafafa !important;
}
.text-silentBlack {
    color: $silentBlack;
}
.text-silentGray {
    color: $silentGray;
}
.text-lightgray {
    color: #BCBCBC;
}
// FONT SIZE
.fontX {
    font-size: $fontX;
}
.fontXX {
    font-size: $fontXX;
}
.fontXS {
    font-size: $fontXS;
}
.fontXM {
    font-size: $fontXM;
}
.fontXL {
    font-size: $fontXL;
}
.fontS {
    font-size: $fontS;
}
.fontSX {
    font-size: $fontSX;
}
.fontSS {
    font-size: $fontSS;
}
.fontSM {
    font-size: $fontSM;
}
.fontSL {
    font-size: $fontSL;
}
.fontM {
    font-size: $fontM;
}
.fontMX {
    font-size: $fontMX;
}
.fontMS {
    font-size: $fontMS;
}
.fontMM {
    font-size: $fontMM;
}
.fontML {
    font-size: $fontML;
}
.fontL {
    font-size: $fontL;
}
.fontLX {
    font-size: $fontLX;
}
.fontLS {
    font-size: $fontLS;
}
.fontLM {
    font-size: $fontLM;
}
.fontLL {
    font-size: $fontLL;
}
.font2XL {
    font-size: $font2XL;
}
.font3XL {
    font-size: $font3XL;
}
.font4XL {
    font-size: $font4XL;
}
.font5XL {
    font-size: $font5XL;
}
.font6XL {
    font-size: $font6XL;
}
.font7XL {
    font-size: $font7XL;
}
.font8XL {
    font-size: $font8XL;
}
.font9XL {
    font-size: $font9XL;
}
.fontXXL {
    font-size: $fontXXL;
}
.res14 {
    @include media-breakpoint-down(lg) {
        font-size: $fontXM;
    }
}
.res15 {
    @include media-breakpoint-down(lg) {
        font-size: $fontXL;
    }
}
.res17 {
    @include media-breakpoint-down(lg) {
        font-size: $fontSX;
    }
}
.res18 {
    @include media-breakpoint-down(lg) {
        font-size: $fontSS;
    }
}
.res16 {
    @include media-breakpoint-down(lg) {
        font-size: $fontS;
    }
}
.res19{
    @include media-breakpoint-down(lg) {
        font-size: $fontSM;
    }
}
.res20{
    @include media-breakpoint-down(lg) {
        font-size: $fontSL;
    }
}
.res23 {
    @include media-breakpoint-down(lg) {
        font-size: $fontM;
    }
}
.res25 {
    @include media-breakpoint-down(lg) {
        font-size: $fontMS;
    }
}
.res26 {
    @include media-breakpoint-down(lg) {
        font-size: $fontMM;
    }
}
.res27 {
    @include media-breakpoint-down(lg) {
        font-size: $fontML;
    }
}
.res31 {
    @include media-breakpoint-down(lg) {
        font-size: $fontLM;
    }
}
.res33 {
    @include media-breakpoint-down(lg) {
        font-size: $font2XL;
    }
}
.res37{
    @include media-breakpoint-down(lg) {
        font-size: 37px;
    }
}
.res40 {
    @include media-breakpoint-down(lg) {
        font-size: $font5XL;
    }
}
.res43 {
    @include media-breakpoint-down(lg) {
        font-size: 43px;
    }
}
.res49 {
    @include media-breakpoint-down(lg) {
        font-size: 49px;
    }
}
.res54 {
    @include media-breakpoint-down(lg) {
        font-size: 54px;
    }
}
.res91 {
    @include media-breakpoint-down(lg) {
        font-size: 91px;
    }
}
// LETTER SPACING
.spaceX {
    letter-spacing: $spaceX;
}
.spaceXX {
    letter-spacing: $spaceXX;
}
.spaceXS {
    letter-spacing: $spaceXS;
}
.spaceXM {
    letter-spacing: $spaceXM;
}
.spaceXL {
    letter-spacing: $spaceXL;
}
.spaceS {
    letter-spacing: $spaceS;
}
.spaceSX {
    letter-spacing: $spaceSX;
}
.spaceSS {
    letter-spacing: $spaceSS;
}
.spaceSM {
    letter-spacing: $spaceSM;
}
.spaceSL {
    letter-spacing: $spaceSL;
}
.spaceM {
    letter-spacing: $spaceM;
}
.spaceMX {
    letter-spacing: $spaceMX;
}
.spaceMS {
    letter-spacing: $spaceMS;
}
.spaceMM {
    letter-spacing: $spaceMM;
}
.spaceML {
    letter-spacing: $spaceML;
}
.spaceL {
    letter-spacing: $spaceL;
}
.spaceLX {
    letter-spacing: $spaceLX;
}
.res-leading {
    @include media-breakpoint-down(lg) {
        line-height: initial !important;
    }
}
.res-space-03 {
    @include media-breakpoint-down(lg) {
        letter-spacing: 0.3px;
    }
}
.res-space-19 {
    @include media-breakpoint-down(lg) {
        letter-spacing: $spaceSM;
    }
}
.res-space-78 {
    @include media-breakpoint-down(lg) {
        letter-spacing: $spaceMS;
    }
}
// BORDER RADIUS
.borderRadiusX {
    border-radius: $borderRadiusX;
}
.borderRadiusS {
    border-radius: $borderRadiusS;
}
// BORDER
.border-gray {
    border: 1px solid $gray;
}
.border-lightgray {
    border: 1px solid #BCBCBC;
}
.border-extraLightgray {
    border: 1px solid rgba(112, 112, 112, 0.18);
}
.smallLeading {
    line-height: $smallLeading;
}
.mediumLeading {
    line-height: $mediumLeading;
}
.leading {
    line-height: $Leading;
}
// OTHERS CSS
.rounded-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rounded-top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.res-shadow-none {
box-shadow:none !important;
}
.opacity-lg-50{
    @include media-breakpoint-up(lg) {
        opacity:0.50;
    }
}

// slick-overflow
.slick-overflow {
  overflow: hidden;
  .slick-slider {
    .slick-list {
      overflow: visible;
    }
  }
}


// Text overflow CSS 
.text-truncate {
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.text-truncate2 {
  -webkit-line-clamp: 2;
}
.text-truncate3 {
  -webkit-line-clamp: 3;
}
.text-truncate4 {
  -webkit-line-clamp: 4;
}
.text-truncate5 {
  -webkit-line-clamp: 5;
}
.text-truncate6 {
  -webkit-line-clamp: 6;
}
.text-truncate8 {
  -webkit-line-clamp: 8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.top-left-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-center {
  top: 50%;
  left: 0%;
  transform: translate(-0%, -50%);
}
.bottom-left {
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}
// CUSTOM PADDING MARGIN
.pt-half {
  @include media-breakpoint-up(lg) {
    padding-top: 2px;
  }
}
.pb-half {
  @include media-breakpoint-up(lg) {
    padding-bottom: 2px;
  }
}
.ps-half {
  @include media-breakpoint-up(lg) {
    padding-left: 2px;
  }
}
.pe-half {
  @include media-breakpoint-up(lg) {
    padding-right: 2px;
  }
}
.mt-half {
  @include media-breakpoint-up(lg) {
    margin-top: 2px;
  }
}
.mb-half {
  @include media-breakpoint-up(lg) {
    margin-bottom: 2px;
  }
}
.ms-half {
  @include media-breakpoint-up(lg) {
    margin-left: 2px;
  }
}
.me-half {
  @include media-breakpoint-up(lg) {
    margin-right: 2px;
  }
}
.px-36 {
  @include media-breakpoint-up(lg) {
    padding-left: 36px;
    padding-right: 36px;
  }
}
// FONT FAMILY CSS
.articulat-m {
  font-family: "ArticulatCF-Medium";
}
.articulat-n {
  font-family: "ArticulatCF-Normal";
}
.articulat-l {
  font-family: "ArticulatCF-Light";
}
.din-b {
  font-family: "DIN-2014_Bold";
}
.din-l {
  font-family: "DIN-2014_Light";
}
.din-r {
  font-family: "DIN-2014_Regular";
}
.res-din-r {
  @include media-breakpoint-down(lg) {
    font-family: "DIN-2014_Regular" !important;
  }
}
.din-d {
  font-family: "DIN-2014_Demi";
}
.futura-m {
  font-family: "futura medium bt";
}
.futura-l {
  font-family: "futura light bt";
}
.devider {
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.30);
}
.border-t {
  border-width: 1px 0 0 0 !important;
}
.border-b {
  border-width: 0 0 1px 0 !important;
}
.res-radius-0 {
  @include media-breakpoint-down(lg) {
    border-radius: 0 !important;
  }
}
.px-p {
  @include media-breakpoint-down(lg) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.px-p-0 {
  @include media-breakpoint-down(lg) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


.object-cover {
  object-fit: cover;
  object-position: center;
}
.list-none {
  list-style: none;
}
.transition {
  transition: all 0.4s ease-in-out;
}
.rotate-circle{
  transform: rotate(45deg);
}
.hover-rotate-circle{
  &:hover{
    transform: rotate(90deg);
  }
}
.p-initial{
  @include media-breakpoint-down(lg) {
  position:initial !important;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e7e7e; 
}
.container{
  max-width:1328px;
  @include media-breakpoint-down(xxl) {
    padding:0 35px;
  }
  @include media-breakpoint-up(xxl) {
    padding: 0;
  }
}
.fw-medium{
  font-weight:500;
}
.row > *{
  @include media-breakpoint-up(lg) {
    padding-left:8px;
    padding-right:8px;
  }
}
.row{
  @include media-breakpoint-up(lg) {
   margin:0 -8px;
  }
}

main{
  padding-top: 180px !important;
  @include media-breakpoint-down(lg) {
    padding-top: 290px !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: 250px !important;
  }
}

.cms-index-index{
  main{
    padding-top: 150px !important;
  }
}

.checkout-cart-index{
  main{
    @include media-breakpoint-down(lg) {
      padding-top: 177px !important;
    }
  }
}

.res-w-100{
  @include media-breakpoint-down(lg) {
    width:100%;
  }
}
.res-y-auto{
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none !important;
  }
  }
}

.left-0{
  left: 0;
}

.bg-none{
  background: none;
}

.loading-mask{
  display: none !important;
}

.text-left{
  text-align: left !important;
}

@include media-breakpoint-up(lg) {
  .text-lg-left{
    text-align: left !important;
  }
}

.object-contain{
  object-fit: contain;
  object-position: center;
}

.mt-n2{
  margin-top: -0.5rem;
}

.amcheckout-block{
  list-style: none;
}

legend.label{
  display: none !important;
}