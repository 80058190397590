.shop--banner{
  position: relative;
  padding-bottom: 120px;
  .banner--rip{
      position: absolute;
  }
  .shop-by--category{
      display: flex;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 25px;
      align-items: center;
      margin-bottom: 25px;
      .img--wrap{
          border-radius: 50%;
          img{
              object-fit: cover;
              height: 100px;
              width: 100px;
          }
      }
      .default--icon{
          width: 30px;
          height: 30px;
          background-color: #CBB484;
          border-radius: 50%;
          img{
              padding: 10px;
              height: 11px;
          }
      }
  }
}

.latest--products{
  .block{
      border-radius: 5px;
      .img--wrap{
          img{
              height: 400px;
          }
      }
  }
}

.hero--banner.wild{
  height: 750px;
  margin-top: -30px;
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 970px) {
      height: 500px;
  }
  .font--brush{
      font-size: 240px;
      position: absolute;
      transform: rotate(-20deg) translateY(-100px);
      @media (max-width: 970p) {
          font-size: 150px;
          line-height: 100px;
          left: 50%;
          transform: translate(-50%, -250px) rotate(-10deg);
      }
  }
  .default--button__gold{
      position: absolute;
      right: 0;
      @media (max-width: 970p) {
          right: unset;
          position: relative;
      }
  }
}

.rip--grey__background{
  position: relative;
  z-index: 1;
}

// Responsive

@media (max-width: 768px) {
}



.navigation--blocks{
  height: 515px;
  &.small{
      height: unset;
  }
  .large--block{
      border-radius: 15px;
      height: 515px;
      width: 100%;
      position: relative;
      .blue--rip{
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
      }
      .font--brush{
          transform: rotate(-15deg);
      }
  }
  .small--block{
      height: 250px;
      border-radius: 15px;
      position: relative;
      .gold--rip{
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
      }
  }
  .headingM{
      font-family: 'Poppins';
  }
  .bodyM{
      font-family: 'Poppins';
  }
}