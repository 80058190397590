
.page-template-contact {

  .contact--top__section {

    padding: 80px 0px;

    .icon-phone {
      display: inline-block;
      transform: rotate(-10deg);
      font-size: 32px;
      margin-bottom: 10px;
      @include animation(shakeThatBooty 5s ease-in-out infinite);
      animation-delay: 2s;

    }
    input[type="text"], textarea {
      width: 100%;
      margin-bottom: 20px;
      background: none;
      border: 1px solid rgb(191, 191, 191);
      padding: 10px 0px 10px 25px;
      font-size: 14px;
      color: $black;
      &::placeholder {
        font-size: 14px;
        color: $black;
      }
    }
    textarea {
      height: 160px;
    }
    input[type="submit"] {
      display: inline-block;
      border: none;
      border-bottom: 1px solid $black;
      background: none;
      cursor: pointer;
      @include generate-transition (all, 500ms, ease);
      &:hover {
        opacity: 0.5;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .home--top__slider {
    height: 60vh;
    .slide {
      height: 60vh;
      .row {
        height: 60vh;
      }
    }
  }

  div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    border-color: $navy;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 500px;
    margin-top: 20px;
  }

  span.wpcf7-not-valid-tip {
    font-size: 14px;
    margin-top: -15px;
    margin-bottom: 5px;
    color: $navy;
  }

  div.wpcf7-mail-sent-ok {
    border-color: $navy;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 500px;
    margin-top: 20px;
  }
}





.block--contact{
  position: relative;
  height: 500px;
  width: 100%;
  .section--title{
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &::after{
      left: 0;
      margin-left: 0;
    }
  }

  p{
    display: inline-block;

  }
  p{
    padding-left:34px;
  }

  .vertically-middle{
    width: 80%;
    left: 10%;
  }

  a{
    color: $black;
    &:hover{
      color: $orange--normal;
    }
  }
}

#map{
  width: 100%;
  height: 500px;
}



body .contact--title{
    margin-top: 60px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    display: inline-block;
    &::after{
      left: 0;
      margin-left: 0;
    }
  }


  .form--contact, .contacts-index-index form{
    padding-bottom: 60px;

    input[type="text"],
    input[type="email"],
    input[type="tel"]{
      border-radius: 0;
      box-shadow: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding-left: 0;
      margin-bottom: 30px;
      padding-bottom: 10px;
      height: auto;

      &:focus{
        border-color: $orange--normal;
      }
    }

    input[type="submit"]{
      background: none;
      border-color: $grey--dark;
      color: $grey--dark;
      font-size: 14px;
      padding: 8px 35px;
      &:hover{
        background: $orange--normal;
        color: $white;
        border-color: $orange--normal;
      }
    }
  }

.cms-contact-us{
  .contact--title{
    display: block;
    text-align: left;
    font-family: $sans-serif;
    margin-bottom: 40px;


    &::after{
      left: 0;
      margin-left: 0;

    }
  }
}


.webforms{
  .field.type-text{
    width: 100%;
  }
  input[type=email], textarea, input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text]{
    width: 100%;
    max-width: 100%;
  }
}
