.loading-mask {
  .loader {
    $loader-size: 40px;
    z-index: 150;

    background-image: url('../images/loader-1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;

    img {
      display: none;
    }

    p {
      margin-top: 100px;
    }
  }
}

.page.messages {
  .messages {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 90;
    background-color: $white;
    padding: 15px 25px;
    border-top-left-radius: 30px;
    color: $navy;
  }
}
