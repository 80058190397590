

.wishlist-index-index{
    button.primary,
    button.action
    {
        @extend .text-decoration-none;
        @extend .text-center;
        @extend .bg-secondary;
        @extend .mt-2;
        @extend .text-extraDarkGray;
        @extend .articulat-n;
        @extend .fontXL;
        @extend .border-0;
        @extend .borderRadiusX;
        @extend .btn-L;
        @extend .d-inline-block;
        min-width: 200px;
        max-width: 100%;
    }
}

.cms-index-index{
    .btn-L{
        line-height: 62px;
    }
}