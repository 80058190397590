

@font-face {
    font-family: 'Cookie';
    src: url('../fonts/Cookie-Regular.woff2') format('woff2'),
        url('../fonts/Cookie-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


  @font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?efjurb');
    src:  url('../fonts/icomoon.eot?efjurb#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?efjurb') format('truetype'),
      url('../fonts/icomoon.woff?efjurb') format('woff'),
      url('../fonts/icomoon.svg?efjurb#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-new-youtube:before {
    content: "\e90c";
    color: #fff;
  }
  .icon-new-instagram:before {
    content: "\e90d";
    color: #fff;
  }
  .icon-new-twitter:before {
    content: "\e90e";
    color: #fff;
  }
  .icon-new-facebook:before {
    content: "\e90f";
    color: #fff;
  }
  .icon-internet:before {
    content: "\e90b";
    color: #fff;
  }
  .icon-chevron:before {
    content: "\e90a";
  }
  .icon-basket:before {
    content: "\e902";
    color: #fff;
  }
  .icon-search:before {
    content: "\e907";
    color: #fff;
  }
  .icon-account:before {
    content: "\e908";
    color: #fff;
  }
  .icon-email:before {
    content: "\e909";
    color: #fff;
  }
  .icon-heart:before {
    content: "\e900";
    color: #1d174c;
  }
  .icon-gold-tick:before {
    content: "\e901";
    color: #cbb484;
  }
  .icon-info:before {
    content: "\e903";
    color: #1c1a49;
  }
  .icon-instagram:before {
    content: "\e904";
    color: #fff;
  }
  .icon-return:before {
    content: "\e905";
    color: #1d184c;
  }
  .icon-van:before {
    content: "\e906";
    color: #1d184c;
  }

  @font-face {
    font-family: "ArticulatCF-Light";
    font-style: normal;
    font-weight: normal;
    src: local('ArticulatCF-Light'), url("../fonts/ArticulatCF-Light.ttf") format("truetype");
}
@font-face {
    font-family: "ArticulatCF-Medium";
    font-style: normal;
    font-weight: normal;
    src: local('ArticulatCF-Medium'), url("../fonts/ArticulatCF-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "ArticulatCF-Normal";
    font-style: normal;
    font-weight: normal;
    src: local('ArticulatCF-Normal'), url("../fonts/ArticulatCF-Normal.ttf") format("truetype");
}
@font-face {
    font-family: "DIN-2014_Bold";
    font-style: normal;
    font-weight: normal;
    src: local('DIN-2014_Bold'), url("../fonts/DIN-2014_Bold.woff") format("woff");
}
@font-face {
    font-family: "DIN-2014_Demi";
    font-style: normal;
    font-weight: normal;
    src: local('DIN-2014_Demi'), url("../fonts/DIN-2014_Demi.woff") format("woff");
}
@font-face {
    font-family: "DIN-2014_Light";
    font-style: normal;
    font-weight: normal;
    src: local('DIN-2014_Light'), url("../fonts/DIN-2014_Light.woff") format("woff");
}
@font-face {
    font-family: "DIN-2014_Regular";
    font-style: normal;
    font-weight: normal;
    src: local('DIN-2014_Regular'), url("../fonts/DIN-2014_Regular.woff") format("woff");
}
@font-face {
    font-family: "futura light bt";
    font-style: normal;
    font-weight: normal;
    src: local('futura light bt'), url("../fonts/futura light bt.ttf") format("truetype");
}
@font-face {
    font-family: "futura medium bt";
    font-style: normal;
    font-weight: normal;
    src: local('futura medium bt'), url("../fonts/futura medium bt.ttf") format("truetype");
}