.secondary {
  .action {
    border-bottom: 1px solid $navy;
    @include generate-transition(all, 500ms, ease);
    &:hover {
      opacity: 0.5;
    }
  }
}


.button--subscribe{
  border: none;
  display: inline;
  width: 100%;
  padding: 2px 5px;
  height: 30px;
  color: #FFFFFF;
  font-size: 15px;
  background: #1D184C url(../images/button-bg.jpg) no-repeat top left;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
}
